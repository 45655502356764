import { useExtractRoute } from 'hooks'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import autocallLogo from 'assets/img/header/autocall.png'
import xBtn from 'assets/img/header/sidetab_xbtn.png'
import arrow from 'assets/img/header/drawer_arrow.svg'
import { HeaderContainer } from '../../../styles/Common/header_styled'
import { usagetoggle } from '../../../recoil/common/atom'
import { useRecoilState } from 'recoil'
import { useRef } from 'react'

const Header = () => {
  const navigate = useNavigate()
  const routes = useExtractRoute('header')
  const inputRef: any = useRef(null)
  const inputRef2: any = useRef(null)
  const { pathname } = useLocation()
  const [tabIdx, setTabIdx] = useRecoilState(usagetoggle)

  const HandelLabel = () => {
    inputRef.current.checked = false
    inputRef2.current.checked = false
  }
  return (
    <HeaderContainer>
      <div className='header-contents'>
        <div className='img-container'>
          <NavLink to=''>
            <img src={autocallLogo} alt='autocall logo' />
          </NavLink>
        </div>
        {routes.map((route) => (
          <NavLink
            className={pathname === '/' ? 'header-nav-item' : 'header-nav-item select'}
            to={route.path}
            key={route.path}
          >
            {route.meta.title}
          </NavLink>
        ))}
        <NavLink className={pathname === '/' ? 'header-mypage-btn' : 'header-mypage-btn select'} to='mypage'>
          마이페이지
        </NavLink>
        <NavLink className='header-nav-item-mobile' to='join'>
          <span>서비스 가입</span>
        </NavLink>
        <input type='checkbox' id='navInput' />
        <label className='hambugerbtn' htmlFor='navInput' onClick={HandelLabel}>
          <div />
          <div />
          <div />
        </label>
        <nav id='nav'>
          <label className='Xbtn' htmlFor='navInput'>
            <img src={xBtn} alt='Xbtn' />
          </label>
          <ul>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={`drawer-nav-item ${pathname === '/mypage' && 'active'}`}>
                <label
                  onClick={() => {
                    navigate('/mypage')
                  }}
                  htmlFor='navInput'
                >
                  마이페이지
                </label>
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={`drawer-nav-item ${pathname === '/join' && 'active'}`}>
                <label
                  onClick={() => {
                    navigate('/join')
                  }}
                  htmlFor='navInput'
                >
                  서비스 가입
                </label>
              </a>
            </li>
            <li>
              <input type='checkbox' id='nav-menu2' ref={inputRef}/>
              <label className='nav-menu' htmlFor='nav-menu2'>
                서비스 이용안내
                <img src={arrow} alt='arrow'/>
              </label>
              <ul className='sub-menu-ul'>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/usage' && tabIdx === 0 && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/usage')
                        setTabIdx(0)
                      }}
                      htmlFor='navInput'
                    >
                      오토콜 알아보기
                    </label>
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/usage' && tabIdx === 1 && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/usage')
                        setTabIdx(1)
                      }}
                      htmlFor='navInput'
                    >
                      ARS호출방법
                    </label>
                  </a>
                </li>
              </ul>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={`drawer-nav-item ${pathname === '/sticker' && 'active'}`}>
                <label
                  onClick={() => {
                    navigate('/sticker')
                  }}
                  htmlFor='navInput'
                >
                  오토콜 주차스티커
                </label>
              </a>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={`drawer-nav-item ${pathname === '/sticker' && 'active'}`}>
                <label
                  onClick={() => {
                    navigate('/qr')
                  }}
                  htmlFor='navInput'
                >
                  QR 인쇄
                </label>
              </a>
            </li>
            <li>
              <input type='checkbox' id='nav-menu' ref={inputRef2}/>
              <label className='nav-menu' htmlFor='nav-menu'>
                고객지원 <img src={arrow} alt='arrow'/>
              </label>
              <ul className='sub-menu-ul'>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/notice' && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/notice')
                      }}
                      htmlFor='navInput'
                    >
                      공지사항
                    </label>
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/news' && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/news')
                      }}
                      htmlFor='navInput'
                    >
                      새소식
                    </label>
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/faq' && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/faq')
                      }}
                      htmlFor='navInput'
                    >
                      자주묻는질문
                    </label>
                  </a>
                </li>
                <li>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a className={`sub-menu ${pathname === '/qna' && 'active'}`}>
                    <label
                      onClick={() => {
                        navigate('/qna')
                      }}
                      htmlFor='navInput'
                    >
                      1:1문의
                    </label>
                  </a>
                </li>
                {(window.location.hostname.includes('servicecancel') || window.location.hostname.includes('localhost')) && (
                  <li>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a className={`sub-menu ${pathname === '/refund' && 'active'}`}>
                      <label
                        onClick={() => {
                          navigate('/refund')
                        }}
                        htmlFor="navInput"
                      >
                        서비스 환불
                      </label>
                    </a>
                  </li>
                )}

              </ul>
            </li>
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className={`drawer-nav-item ${pathname === '/cancel' && 'active'}`}>
                <label
                  onClick={() => {
                    navigate('/cancel')
                  }}
                  htmlFor="navInput"
                >
                  서비스 해지
                </label>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </HeaderContainer>
  )
}

export default Header
