import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const userSessionID = atom<string>({
  key: 'userSessionID',
  default: ''
})
export const userPNumber = atom<string>({
  key: 'userPNumber',
  default: ''
})
export const userIsFreeMember = atom<boolean>({
  key: 'userIsFreeMember',
  default: false
})
export const userCarNum = atom<string>({
  key: 'userCarNum',
  default: ''
})
export const userFTelecom = atom<Telecom>({
  key: 'userFTelecom',
  default: 0
})
export const userFNumber = atom<string>({
  key: 'userFNumber',
  default: ''
})
export const userSwitch = atom<SwitchType>({
  key: 'userSwitch',
  default: 'P'
})
export const userGetCouponDT = atom<string>({
  key: 'userGetCouponDT',
  default: ''
})
export const userFreeExireDT = atom<string>({
  key: 'userFreeExireDT',
  default: ''
})
export const userRegDT = atom<string>({
  key: 'userRegDT',
  default: ''
})
export const userMemberType = atom<number>({
  key: 'userMemberType',
  default: 0
})
export const userSendFlag = atom<boolean>({
  key: 'userSendFlag',
  default: false
})
export const userLastSendDT = atom<string>({
  key: 'userLastSendDT',
  default: ''
})
export const userAddress1 = atom<string>({
  key: 'userAddress1',
  default: ''
})
export const userAddress2 = atom<string>({
  key: 'userAddress2',
  default: ''
})
export const userPostCode = atom<string>({
  key: 'userPostCode',
  default: ''
})
export const userUName = atom<string>({
  key: 'userUName',
  default: ''
})
export const userRecvNumber = atom<string>({
  key: 'userRecvNumber',
  default: ''
})
export const successStickerTerm = atom<TermsType>({
  key: 'successStickerTerm',
  default: 0
})
export const resLoginUser = atom<ResponseLoginBody>({
  key: 'resLoginUser',
  default: {
    Telecom: 0,
    PNumber: '',
    IsFreeMember: false,
    CarList: [{ CarNum: '', LinkNumber: '', LinkTelecom: 0 }],
    CarNum: '',
    SessionID: ''
  }
})
export const resStickerHistory = atom<ResponseStickerHistoryInquiryBody>({
  key: 'resStickerHistory',
  default: {
    SendFlag: false,
    LastSendDT: '',
    Address1: '',
    Address2: '',
    Postcode: '',
    UName: '',
    RecvNumber: ''
  }
})
export const newCarNumber = atom<string>({
  key: 'newCarNumber',
  default: ''
})
export const registQNA = atom<RequestRegistQuestionBody>({
  key: 'registQNA',
  default: {
    ContentsType: 0,
    UName: '',
    PNumber: '',
    EMail: '',
    Title: '',
    Contents: '',
    AgreeQNA: 0
  }
})
export const qnaContentsType = atom<number>({
  key: 'qnaContentsType',
  default: 0
})
export const qnaUName = atom<string>({
  key: 'qnaUName',
  default: ''
})
export const qnaPNumber = atom<string>({
  key: 'qnaPNumber',
  default: ''
})
export const qnaEMail = atom<string>({
  key: 'Title',
  default: ''
})
export const qnaTitle = atom<string>({
  key: 'qnaTitle',
  default: ''
})
export const qnaContents = atom<string>({
  key: 'qnaContents',
  default: ''
})
export const qnaAgreeQNA = atom<TermsType>({
  key: 'qnaAgree',
  default: 0
})
export const modaltypeoption = atom<number>({
  key: 'modaltypeoption',
  default: 10
})
export const qrUrl = atom<ResponseQRUrlBody>({
  key: 'qrUrl',
  default: {
    URL: ''
  },
  effects_UNSTABLE: [persistAtom],
})
export const qrLoginCheck = atom<boolean>({
  key: 'qrLoginCheck',
  default: false
})
export const refundBankContentsType = atom<number>({
  key: 'refundBankContentsType',
  default: 0
})
export const refundBantAccount = atom<string>({
  key: 'refundBantAccount',
  default: ''
})
export const refundAgreeRefund = atom<TermsType>({
  key: 'refundAgreeRefund',
  default: 0
})
export const refundUName = atom<string>({
  key: 'refundUName',
  default: ''
})
export const refundCaptchaAuthToken = atom<string>({
  key: 'refundCaptchaAuthToken',
  default: ''
})
export const refundLGTerms = atom<ResponseRefundTermsBody>({
  key: 'refundLGTerms',
  default: {
    Idx:	0,
    SKTTitle:	'',
    SKTTerms:	'',
    KTTitle:	'',
    KTTerms:	'',
    LgupTitle:	'',
    LgupTerms:	'',
    RegDT:	'',
  }
})
export const refundCapthca = atom<ResponseRefundCaptchaBody>({
  key: 'refundCapthca',
  default: {
    CaptchaID: '',
    ImgB64:	'',
  }
})
export const refundCaptchaErr = atom<string>({
  key: 'refundCaptchaErr',
  default: ''
})