import Utils from 'utils'

const useCookies = () => {
  function set (cName: string, cValue: string, time?: number) {
    Utils.common.setCookie(cName, cValue, time)
  }
  function get (cName: string) {
    return Utils.common.getCookie(cName)
  }
  function del (cName: string) {
    Utils.common.deleteCookie(cName)
  }
  function login (PNumber: string, SessionID: string, Telecom: string) {
    set('PNumber', PNumber)
    set('SessionID', SessionID)
    set('Telecom', Telecom)
  }
  function logout () {
    del('PNumber')
    del('SessionID')
    del('Telecom')
  }
  function getSessionCookie () {
    return {
      PNumber: get('PNumber'),
      SessionID: get('SessionID'),
      Telecom: get('Telecom')
    }
  }
  return {
    set,
    get,
    del,
    login,
    logout,
    getSessionCookie
  }
}
export default useCookies
