import styled from 'styled-components'
import STYLE_GUIDE from '../../service/constants/styleGuide'

const QRFavorSectionWrap = styled.div`
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        display: flex;
        height: 146px;
        padding: 30px;
        align-items: center;
        gap: 30px;
        flex-shrink: 0;
        border-radius: 30px;
        background: #F6F8F9;
        margin-bottom: 100px;

        .favor-message {
            display: flex;
            flex-direction: column;
            width: 673px;
            gap: 15px;
            
            > p {
                color: #2B3639;
                font-family: GmarketSansMedium, serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
            }

            > span {
                color: #7D898B;
                font-family: GmarketSansMedium, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
            }    
        }
        

        > button {
          cursor: pointer;
          display: flex;
          width: 170px;
          height: 50px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 10px;
          background: #10A8CA;
          border: none;
          color: #FFF;
          text-align: center;
          font-family: GmarketSansMedium, serif;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 100% */
        }

    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        display: flex;
        width: 320px;
        padding: 30px;
        flex-direction: column;
        align-items: flex-start;
        gap: 30px;
        border-radius: 20px;
        background: #F6F8F9;
        
        .favor-message {
            display: flex;
            flex-direction: column;
            gap: 15px;
            
            > p {
                color: #2B3639;
                font-family: GmarketSansMedium, serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin: 0;
            }
            
            > span {
                color: #7D898B;
                font-family: GmarketSansMedium, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; /* 150% */
            }
        }
        
        > button {
            display: flex;
            height: 40px;
            padding: 21px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            align-self: stretch;
            border-radius: 10px;
            border: none;
            background: #CAF5FF;
            color: #10A8CA;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 14px; /* 100% */
        }
    }
`

const QRFavorSection = () => {
  const handleFavor = () => {
    window.location.href = 'mypage'
  }

  return (
    <QRFavorSectionWrap>
      <div className='favor-message'>
        <p>주차스티커 무료 증정 혜택!</p>
        <span>최초 가입자라면 차량번호 등록하고 주차스티커를 무료로 받으세요</span>
      </div>
      <button onClick={handleFavor}>혜택 신청하기</button>
    </QRFavorSectionWrap>
  )
}

export default QRFavorSection;