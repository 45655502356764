import {AutocallForm, AutocallFormItem} from '../../Common/form'
import AutocallInput from '../../Common/form/AutocallInput'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import {useValidate} from '../../../hooks'
import AutocallSelector, { SelectboxOptionType } from 'components/Common/form/AutocallSelector'
import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import {useEffect, useRef} from 'react'
import {
  refundAgreeRefund, refundBankContentsType,
  refundBantAccount, refundUName
} from '../../../recoil/user/atom'
import RefundTerm from './RefundTerm'
import AutocallBtn from '../../Common/AutocallBtn'
import {inputDisabled, validateTypeFam} from '../../../recoil/validate/atom'
import {
  otpAuthType,
  otpCryptoNumber,
  otpPNumber,
  otpTelecom,
  otpTimeOut,
  otpTransctionID
} from '../../../recoil/otp/atom'
import {TELECOM} from '../../../service/constants/common'
import utils from '../../../utils'
import {useRefundAPI} from '../../../hooks/useRefundAPI.hook'
import {ServiceCancel} from '../../../recoil/common/atom'

const RefundMsgWrapper = styled.div`
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        display: flex;
        width: 730px;
        height: 120px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #F5F5F5;

        > p {
            color: #2B3639;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 160% */
            margin: 0;
        }

        > span {
            color: #2B3639;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:before {
                content: "* 서비스 이용 이력이 없을 경우 환불이 가능합니다.";
                color: #F00;
            }
        }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        display: flex;
        width: 320px;
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
        border-radius: 10px;
        background: #F5F5F5;
        margin-bottom: 20px;

        > p {
            color: #2B3639;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px; /* 137.5% */
            margin: 0;
        }

        > span {
            color: #2B3639;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            &:before {
                content: "* 서비스 이용 이력이 없을 경우 환불이 가능합니다.";
                color: #F00;
            }
        }
    }
`

export const bankOptions: SelectboxOptionType[] = [
  { label: '기업은행', value: 1 },
  { label: '국민은행', value: 2 },
  { label: '신한은행', value: 3 },
  { label: '우리은행', value: 4 },
  { label: 'KEB하나은행', value: 5 },
  { label: '농협은행', value: 6 },
  { label: '지역농축협', value: 7 },
  { label: 'SC제일은행', value: 8 },
  { label: '한국시티은행', value: 9 },
  { label: '우체국', value: 10 },
  { label: '경남은행', value: 11 },
  { label: '광주은행', value: 12 },
  { label: '대구은행', value: 13 },
  { label: '도이치', value: 14 },
  { label: '부산은행', value: 15 },
  { label: '산림조합', value: 16 },
  { label: '산업은행', value: 17 },
  { label: '저축은행', value: 18 },
  { label: '새마을금고', value: 19 },
  { label: '수협', value: 20 },
  { label: '신협', value: 21 },
  { label: '전북은행', value: 22 },
  { label: '제주은행', value: 23 },
  { label: 'BOA', value: 24 },
  { label: 'HSBC', value: 25 },
  { label: 'JP모간', value: 26 },
  { label: '중국공상은행', value: 27 },
  { label: '비엔피파리바은행', value: 28 },
  { label: '중국건설은행', value: 29 },
  { label: '카카오뱅크', value: 30 },
  { label: '케이뱅크', value: 31 },
  { label: '교보증권', value: 32 },
  { label: '대신증권', value: 33 },
  { label: '미래에셋대우', value: 34 },
  { label: 'DB금융투자', value: 35 },
  { label: '유안타증권', value: 36 },
  { label: '메리츠증권', value: 37 },
  { label: '부국증권', value: 38 },
  { label: '삼성증권', value: 39 },
  { label: '솔로몬투자증권', value: 40 },
  { label: '신영증권', value: 41 },
  { label: '신한금융투자', value: 42 },
  { label: 'NH투자증권', value: 43 },
  { label: '유진투자증권', value: 44 },
  { label: '이베스트증권', value: 45 },
  { label: '키움증권', value: 46 },
  { label: '하나금융투자', value: 47 },
  { label: '하이투자', value: 48 },
  { label: '한국투자', value: 49 },
  { label: '한화증권', value: 50 },
  { label: 'KB증권', value: 51 },
  { label: '현대차투자증권', value: 52 },
  { label: '토스뱅크', value: 53 },
  { label: '케이프투자증권', value: 54 },
  { label: 'SK증권', value: 55 },
  { label: 'KTB투자증권', value: 56 },
  { label: '한국포스증권', value: 57 },
]

const Refund = () => {
  const serviceCancel = useRecoilValue(ServiceCancel)
  const [bankAccount, setBankAccount] = useRecoilState(refundBantAccount)
  const [uName, setUName] = useRecoilState(refundUName)
  const [bankType, setBankType] = useRecoilState(refundBankContentsType)
  const [pNumber, setPNumber] = useRecoilState(otpPNumber)
  const [certNum, setCertNum] = useRecoilState(otpCryptoNumber)
  const setAuthType = useSetRecoilState(otpAuthType)
  const setPTelecom = useSetRecoilState(otpTelecom)
  const [agree, setAgree] = useRecoilState(refundAgreeRefund)
  const [getOtpTimeOut, setOtpTimeOut] = useRecoilState(otpTimeOut)
  const [getOtpTransactionID, setOtpTransactionID] = useRecoilState(otpTransctionID)
  const refundBankTypeValidState = useRecoilState(validateTypeFam('refundBankType'))
  const refundAgreeValidState = useRecoilState(validateTypeFam('refundAgree'))
  const pNumValidState = useRecoilState(validateTypeFam('refundPNumber'))
  const refundBankAccountValidState = useRecoilState(validateTypeFam('refundBankAccount'))
  const refundUnameValidState = useRecoilState(validateTypeFam('refundUName'))
  const setPhoneInput = useSetRecoilState(inputDisabled('phoneInput'))
  const {replace, join} = useValidate()
  const { certNumber, phoneNumber } = join
  const {refundPNumberV, refundBankTypeV, refundBankAccountV, refundUNameV, refundAgreeV} = useValidate().refund
  const {replaceRefundName, replaceBankAccount, replacePNumber} = replace
  const numberRef = useRef<HTMLInputElement>(null)
  const bankAccountRef = useRef<HTMLInputElement>(null)
  const certRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)
  const [timeLeft, setTimeLeft] = useRecoilState(otpTimeOut)
  const timerRef = useRef<NodeJS.Timeout>()
  const refund = useRefundAPI()

  useEffect(() => {
    refundBankTypeValidState[1]({ valid: true, msg: '' })
    refundAgreeValidState[1]({ valid: true, msg: '' })
    pNumValidState[1]({ valid: true, msg: '' })
    refundBankAccountValidState[1]({ valid: true, msg: '' })
    refundUnameValidState[1]({ valid: true, msg: '' })
    setUName('')
    setPNumber('')
    setBankAccount('')
    setCertNum('')
    setAuthType('delete')
    setPhoneInput({isDisabled: false})
    setPTelecom(TELECOM.LGU)
    // setPTelecom(TELECOM.SKT)
    setOtpTimeOut(0)
    setOtpTransactionID('')
    refund.terms().then()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    executeOTP()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOtpTimeOut, getOtpTransactionID])
  useEffect(() => {
    if (!serviceCancel){
      if (window.location.hostname.includes('dev')){
        window.location.href = 'dev-www.autocall.ai'
      } else {
        window.location.href = 'www.autocall.ai'
      }
    }
  }, [serviceCancel])

  const executeOTP = () => {
    if (getOtpTimeOut > 0 && getOtpTransactionID !== '') {
      if (timeLeft !== 180 && timeLeft > 160) {
      } else if (timeLeft === 180 || timeLeft <= 160) {
        phoneNumber.recoilState[1]({ valid: false, msg: '100' })
        setPhoneInput({isDisabled: true})
        clearInterval(timerRef.current)
        startTimer()
      } else {
        setPhoneInput({isDisabled: false})
        certNumber.recoilState[1]({ valid: true, msg: '202' })
        phoneNumber.recoilState[1]({ valid: false, msg: '202' })
        setOtpTimeOut(0)
        setOtpTransactionID('')
      }
    } else {
      clearInterval(timerRef.current)
      setTimeLeft(180)
      setPhoneInput({isDisabled: false})
      setOtpTimeOut(0)
      setOtpTransactionID('')
    }
  }

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft > 0) {
          return prevTimeLeft - 1
        } else {
          // certNumber.recoilState[1]({ valid: true, msg: '202' })
          // phoneNumber.recoilState[1]({ valid: false, msg: '202' })
          return prevTimeLeft
        }
      })
    }, 1000)
  }

  const submitRefundForm = async () => {
    refundPNumberV.validFunc(pNumber)
    certNumber.validFunc(certNum)
    refundBankTypeV.validFunc(`${bankType}`)
    refundBankAccountV.validFunc(bankAccount)
    refundUNameV.validFunc(uName)
    refundAgreeV.validFunc(`${agree}`)
    if (!refundPNumberV.validFunc(pNumber)){
      window.scrollTo({
        top: 300,
        behavior: 'smooth'
      })
    }
    else if (!certNumber.validFunc(certNum)) {
      window.scrollTo({
        top: 420,
        behavior: 'smooth'
      })
    }
    else if (!refundBankTypeV.validFunc(`${bankType}`)) {
      window.scrollTo({
        top: 570,
        behavior: 'smooth'
      })
    }
    else if (!refundBankAccountV.validFunc(bankAccount)) {
      window.scrollTo({
        top: 720,
        behavior: 'smooth'
      })
    }
    else if (!refundUNameV.validFunc(uName)) {
      window.scrollTo({
        top: 870,
        behavior: 'smooth'
      })
    }
    else if (!refundAgreeV.validFunc(`${agree}`)) {
      window.scrollTo({
        top: 990,
        behavior: 'smooth'
      })
    }
    else if (
      refundPNumberV.validFunc(pNumber) &&
      certNumber.validFunc(certNum) &&
      refundBankTypeV.validFunc(`${bankType}`) &&
      refundBankAccountV.validFunc(bankAccount) &&
      refundUNameV.validFunc(uName) &&
      refundAgreeV.validFunc(`${agree}`)
    ) {
      //환불 요청 신청하고, 데이터 베이스에 등록 후 환불 에상금액을 받아오고 handleSuccess진행
      await refund.send()
    }
  }

  return (
    <div>
      <RefundMsgWrapper>
        <p>서비스 환불을 신청하기 전에 아래 내용을 꼭 확인해 주세요.</p>
        <span>
          <br/>* 환불 신청 시 서비스는 자동으로 해지됩니다.
        </span>
      </RefundMsgWrapper>
      <AutocallForm>
        <AutocallFormItem title="휴대폰 번호" validate={refundPNumberV.recoilState[0]} essential>
          <AutocallInput
            inputRef={numberRef}
            inputType="tel"
            placeholder="‘-’제외 하고 입력해주세요."
            state={utils.common.parsePNumber(pNumber)}
            setState={setPNumber}
            validate={refundPNumberV}
            // onKeyup={handleSendBtnClick}
            replacer={replacePNumber}
            maxLength={13}
          />
        </AutocallFormItem>
        <AutocallFormItem title="인증번호 입력" validate={certNumber.recoilState[0]} essential>
          <AutocallInput
            inputRef={certRef}
            inputType="tel"
            placeholder="인증번호를 입력해주세요."
            state={certNum}
            setState={setCertNum}
            validate={certNumber}
            // onKeyup={handleSendBtnClick}
            isCount
            timeRef={timeLeft}
            replacer={replacePNumber}
            maxLength={4}
          />
        </AutocallFormItem>
        <AutocallFormItem title='환불 받을 은행 선택' validate={refundBankTypeV.recoilState[0]} essential>
          <AutocallSelector
            state={bankType}
            setState={setBankType}
            placeholder='은행을 선택해주세요.'
            options={bankOptions}
            validate={refundBankTypeV}
          />
        </AutocallFormItem>
        <AutocallFormItem title="계좌번호 입력" validate={refundBankAccountV.recoilState[0]} essential>
          <AutocallInput
            inputRef={bankAccountRef}
            inputType="tel"
            placeholder="계좌번호를 입력해주세요."
            state={bankAccount}
            setState={setBankAccount}
            validate={refundBankAccountV}
            // onKeyup={handleSendBtnClick}
            replacer={replaceBankAccount}
            maxLength={16}
          />
        </AutocallFormItem>
        <AutocallFormItem title="예금주명 입력" validate={refundUNameV.recoilState[0]} essential>
          <AutocallInput
            inputRef={nameRef}
            inputType="text"
            placeholder="예금주명을 입력해주세요."
            state={uName}
            setState={setUName}
            validate={refundUNameV}
            // onKeyup={handleSendBtnClick}
            replacer={replaceRefundName}
            maxLength={3000}
          />
        </AutocallFormItem>
        <AutocallFormItem title=' ' validate={refundAgreeV.recoilState[0]}>
          <RefundTerm
            title='개인정보 수집 및 이용동의(필수)'
            state={agree}
            setState={setAgree}
            validate={refundAgreeV}
          />
        </AutocallFormItem>
        <AutocallFormItem>
          <div className='refund-submit'>
            <AutocallBtn title='서비스 해지 후 환불 등록' type='default' onClickFunc={submitRefundForm} />
            <p className='under-text'>*가입 당일 해지하신 고객님은 요금이 청구되지 않습니다.<br/>
              서비스 이용요금은 매월 사용일 수 만큼 계산되어 청구됩니다.</p>
          </div>
        </AutocallFormItem>
      </AutocallForm>
    </div>
  )
}

export default Refund