import styled from 'styled-components'
import STYLE_GUIDE from '../../service/constants/styleGuide'
import qrImageType1 from 'assets/img/qr/qrImageType1.svg'
import qrImageType2 from 'assets/img/qr/qrImageType2.svg'
import qrImageType3 from 'assets/img/qr/qrImageType3.svg'
import qrImageType1Checked from 'assets/img/qr/qrImageType1Checked.svg'
import qrImageType2Checked from 'assets/img/qr/qrImageType2Checked.svg'
import qrImageType3Checked from 'assets/img/qr/qrImageType3Checked.svg'
import qrImageType1CheckedMo from 'assets/img/qr/qrImageType1CheckedMo.svg'
import qrImageType2CheckedMo from 'assets/img/qr/qrImageType2CheckedMo.svg'
import qrImageType3CheckedMo from 'assets/img/qr/qrImageType3CheckedMo.svg'
import SmallType from './QRImageBackground/SmallType'
import {useEffect, useRef, useState} from 'react'
import BigType from './QRImageBackground/BigType'
import useCookies from '../../hooks/useCookies.hook'
import {useRecoilValue, useResetRecoilState, useSetRecoilState} from 'recoil'
import {qrLoginCheck, qrUrl} from '../../recoil/user/atom'
import ReactToPrint from 'react-to-print'
import {useNavigate} from 'react-router-dom'

const QRImageSectionWrap = styled.div`
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 100%;
        height: 674px;
        border-radius: 30px;
        border: 1px solid #DFE8EA;
        background: #F0F2F4;
        box-shadow: 0 4px 14px 0 rgba(227, 244, 248, 0.70);
        margin-bottom: 60px;

        > section {
            display: flex;

            .pc {
                display: flex;
                width: 170px;
                height: 563px;
                padding: 30px;
                align-items: flex-start;
                gap: 20px;
                flex-shrink: 0;
                border-radius: 30px 0 0 0;
                background: #FAFAFA;

                > span {
                    color: #000;
                    font-family: GmarketSansMedium, serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
            
            .mo {
                display: none;
            }
            
            > div {
                display: flex;
                width: 770px;
                height: 563px;
                padding: 37px 140px;
                flex-direction: column;
                align-items: flex-start;
                gap: 37px;
                flex-shrink: 0;
            }
        }

        > div {
            width: 100%;
            height: 110px;
            border-radius: 0 0 30px 30px;
            background: #FFF;
            padding: 29px 348px 31px;
            
            > button {
                cursor: pointer;
                display: flex;
                height: 50px;
                width: 244px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-shrink: 0;
                border-radius: 8px;
                background: #10A8CA;
                border: none;
                color: #FFF;
                text-align: center;
                font-family: GmarketSansMedium, serif;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 16px; /* 100% */
            }
        }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 100%;
        height: 436px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        border-radius: 30px;
        border: 1px solid #DFE8EA;
        background: #F0F2F4;
        box-shadow: 0 4px 14px 0 rgba(227, 244, 248, 0.70);
        margin-bottom: 50px;
        
        > section {
            
            .pc {
                display: none;
            }
            
            > div {
                display: flex;
                height: 256px;
                padding: 28px 55px;
                align-items: center;
                gap: 60px;
                //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.10);
            }

            .mo {
                display: flex;
                width: 320px;
                height: 180px;  
                padding: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
                flex-shrink: 0;
                border-radius: 0 0 30px 30px;
                background: #FFF;

                > div {
                    display: flex;
                    gap: 16px;
                    
                    > span {
                        width: 50px;
                        align-self: stretch;
                        color: #000;
                        font-family: GmarketSansMedium, serif;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                > button {
                    cursor: pointer;
                    display: flex;
                    width: 260px;
                    height: 50px;
                    //padding: 21px 91px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 8px;
                    background: #10A8CA;
                    border: none;
                    color: #FFF;
                    text-align: center;
                    font-family: GmarketSansMedium, serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 16px; /* 100% */
                    margin-left: 14px;
                }
            }
        }
        
        > div {
            display: none;
        }
    }
`

const urlBackground:QRImage[] = [
  {
    idx: 0,
    img: qrImageType1,
    imgChecked: qrImageType1Checked,
    imgCheckedMobile: qrImageType1CheckedMo,
    alt: 'The first QR Image type!'
  },
  {
    idx: 1,
    img: qrImageType2,
    imgChecked: qrImageType2Checked,
    imgCheckedMobile: qrImageType2CheckedMo,
    alt: 'The second QR Image type!'
  },
  {
    idx: 2,
    img: qrImageType3,
    imgChecked: qrImageType3Checked,
    imgCheckedMobile: qrImageType3CheckedMo,
    alt: 'The third QR Image type!'
  },
]

const QRImageSection = () => {
  const [imageCheck, setImageCheck] = useState<number>(0)
  const resetQRImg = useResetRecoilState(qrUrl)
  const setQrLoginChecker = useSetRecoilState(qrLoginCheck)
  const qrImg = useRecoilValue(qrUrl).URL
  const { getSessionCookie } = useCookies()
  const session = getSessionCookie()
  const componentRef = useRef(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (session.SessionID === '' && session.Telecom === '' && session.PNumber === ''){
      resetQRImg()
    }
  }, [session.PNumber, session.SessionID, session.Telecom, resetQRImg])

  const handleOnClick = (index: number) => {
    setImageCheck(index)
  }
  const handleLogin = () => {
    setQrLoginChecker(true)
    navigate('/mypage');
  }

  return (
    <QRImageSectionWrap>
      <section>
        <div className="pc">
          <span>템플릿 선택</span>
          {
            urlBackground.map((data, index) =>
              <SmallType urlBackground={data} handleOnClick={handleOnClick} checked={imageCheck} key={index} />
            )
          }
        </div>
        <div ref={componentRef}>
          {
            urlBackground.filter((data)=>data.idx === imageCheck).map((data) =>
              <BigType urlBackground={data} key={data.idx}/>
            )
          }
        </div>
        <div className='mo'>
          <div>
            <span>템플릿 선택</span>
            {
              urlBackground.map((data, index) =>
                <SmallType urlBackground={data} handleOnClick={handleOnClick} checked={imageCheck} key={index} />
              )
            }
          </div>
          {qrImg === '' ? <button onClick={handleLogin}>로그인 후 인쇄하기</button> :
            <ReactToPrint
              trigger={() => <button>인쇄하기</button>}
              content={() => componentRef.current}
              pageStyle="@page { size: A4; ratio:60%; }"
            />
          }
        </div>
      </section>
      <div>
        {qrImg === '' ? <button onClick={handleLogin}>로그인 후 인쇄하기</button> :
          <ReactToPrint
            trigger={() => <button>인쇄하기</button>}
            content={() => componentRef.current}
            pageStyle="@page { size: A4; ratio:60%; }"
          />
        }
      </div>
    </QRImageSectionWrap>
  )
}

export default QRImageSection;