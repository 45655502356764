import { useLocation, useNavigate } from 'react-router-dom'
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpCryptoNumber, otpFNumber, otpFTelecom, otpTransctionID } from 'recoil/otp/atom'
import {qrUrl, resLoginUser, resStickerHistory} from 'recoil/user/atom'
import { withQNAForm } from 'recoil/user/withQNA'
import { userWithRequestSticker } from 'recoil/user/withSticker'
import { CMD_TYPE } from 'service/constants/common'
import useAPI from './useAPI.hook'
import useCookies from './useCookies.hook'
import useLoading from './useLoading.hook'
import useModal from './useModal.hook'

const useSticker = (user: ResponseLoginBody) => {
  const loading = useLoading()
  const { pathname } = useLocation()
  const setSticker = useSetRecoilState(resStickerHistory)
  const inputSticker = useRecoilValue(userWithRequestSticker)
  const api = useAPI()
  const modal = useModal()
  const navigate = useNavigate()
  async function checkSendHistory () {
    if (user.PNumber && user.SessionID) {
      loading.on()
      const data: RequestStickerHistoryInquiryBody = {
        PNumber: user.PNumber,
        SessionID: user.SessionID
      }
      const result = await api.requestSRS<ResponseStickerHistoryInquiryBody>(CMD_TYPE.STICKER_HISTORY_INQUIRY, data)
      if (result != null) {
        setSticker(result)
        loading.off()
        return true
      } else {
        loading.off()
        return false
      }
    } else {
      return false
    }
  }
  async function requestSticker () {
    loading.on()
    const data: RequestSelectAndSendStickerBody = {
      PNumber: user.PNumber,
      PCode: 'autocall_homepage',
      SessionID: user.SessionID,
      RecvNumber: inputSticker.RecvNumber,
      Address1: inputSticker.Address1,
      Address2: inputSticker.Address2,
      Postcode: inputSticker.Postcode,
      UName: inputSticker.UName
    }
    // console.log(data)
    const result = await api.requestSRS<ResponseStickerHistoryInquiryBody>(CMD_TYPE.SELECT_AND_SEND_STICKER, data)
    if (result != null) {
      setSticker(result)
      pathname.includes('success')
        ? modal.alert({
          title: '혜택 신청이 완료되었습니다.',
          contents: '서비스 1개월 유지 시 오토콜 무점착 스티커를 보내드립니다.',
          onCancel: () => {
            navigate('/mypage')
          },
          onConfirm: () => {
            navigate('/mypage')
          }
        })
        : modal.alert({
          title: '혜택 신청이 완료되었습니다.',
          contents: '서비스 1개월 유지 시 오토콜 무점착 스티커를 보내드립니다.',
          onConfirm: () => {
            navigate('/mypage')
          }
        })
      loading.off()
      return true
    } else {
      loading.off()
      return false
    }
  }
  return {
    check: checkSendHistory,
    request: requestSticker
  }
}
const useCar = (user: ResponseLoginBody, setter: SetterOrUpdater<ResponseLoginBody>) => {
  const api = useAPI()
  const loading = useLoading()
  async function checkCarNumber (carNum: string) {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.CHANGE_CAR_NUMBER, { CarNum: carNum })
    if (result) {
      // console.log(result)
    }
    loading.off()
  }
  async function updateCarNumber (carNum: string) {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.UPDATE_CAR_NUMBER, {
      Telecom: user.Telecom,
      PNumber: user.PNumber,
      CarNum: carNum
    })
    if (result) {
      setter((user) => {
        user.CarNum = carNum
        return user
      })
    }
    loading.off()
  }
  async function addNewCarNumber (carNum: string, linkNum?: string, linkTel?: Telecom) {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.ADD_CAR_NUMBER, {
      SessionID: user.SessionID,
      PNumber: user.PNumber,
      CarNum: carNum,
      LinkNumber: user.PNumber,
      LinkTelecom: user.Telecom
    })
    if (result) {
      setter((user) => {
        user.CarList?.push({
          CarNum: carNum,
          LinkNumber: linkNum ?? user.PNumber,
          LinkTelecom: linkTel ?? user.Telecom
        })
        return user
      })
    }
    loading.off()
  }
  async function deleteCarNumber (carNum: string) {
    loading.on()
    if ((user.CarList != null) && user.CarList.length > 0) {
      const result = await api.requestSRS<unknown>(CMD_TYPE.DELETE_CAR_NUMBER, {
        SessionID: user.SessionID,
        PNumber: user.PNumber,
        CarNum: carNum
      })
      if (result) {
        setter((user) => {
          user.CarList = user.CarList?.filter((car) => car.CarNum !== carNum)
          return user
        })
      }
    } else {
      alert('삭제할 대상이 없습니다.')
    }
    loading.off()
  }
  async function changeLinkedCar (carNum: string, linkNum: string, linkTel: Telecom) {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.CHANGE_RINKED_CAR_NUMBER, {
      SessionID: user.SessionID,
      PNumber: user.PNumber,
      CarNum: carNum,
      LinkNumber: linkNum,
      LinkTelecom: linkTel
    })
    if (result) {
      setter((user) => {
        user.CarList = user.CarList?.map((car) => {
          if (car.CarNum === carNum) {
            return {
              CarNum: carNum,
              LinkNumber: linkNum,
              LinkTelecom: linkTel
            }
          } else {
            return car
          }
        })
        return user
      })
    }
    loading.off()
  }
  return {
    check: checkCarNumber,
    add: addNewCarNumber,
    update: updateCarNumber,
    delete: deleteCarNumber,
    changeLink: changeLinkedCar
  }
}
const useAdditionalUser = (user: ResponseLoginBody, setter: SetterOrUpdater<ResponseLoginBody>) => {
  const api = useAPI()
  const loading = useLoading()
  const [FNum, setFNum] = useRecoilState(otpFNumber)
  const [FTel, setFTel] = useRecoilState(otpFTelecom)
  const otpTransactionID = useRecoilValue(otpTransctionID)
  const certNum = useRecoilValue(otpCryptoNumber)
  const modal = useModal()

  async function addAdditionalUser () {
    loading.on()
    const data: RequestCertOTPBody = {
      SessionID: user.SessionID,
      AuthType: 'fadd',
      FTelecom: FTel,
      FNumber: FNum,
      PNumber: user.PNumber,
      Telecom: user.Telecom,
      CryptoNumber: certNum,
      TransactionID: otpTransactionID,
      PCode: 'autocall_homepage',
      CallType: 'WEB'
    }
    const result = await api.requestSRS<unknown>(CMD_TYPE.CERT_OTP, data)
    if (result) {
      setter((user) => {
        const set: ResponseLoginBody = {
          CarNum: user.CarNum,
          IsFreeMember: user.IsFreeMember,
          PNumber: user.PNumber,
          SessionID: user.SessionID,
          Telecom: user.Telecom,
          AlreadyRecvSticker: user.AlreadyRecvSticker,
          CarList: user.CarList,
          FNumber: FNum,
          FreeExpireDT: user.FreeExpireDT,
          FTelecom: FTel,
          GetCouponDT: user.GetCouponDT,
          MemberType: user.MemberType,
          RegDT: user.RegDT,
          Switch: user.Switch
        }
        return set
      })
      setFNum('')
      setFTel(0)
      modal.alert({
        title: '추가연락처 등록',
        contents: '추가 연락처 등록이 완료되었습니다.'
      })
      loading.off()
      return true
    } else {
      loading.off()
      return false
    }
  }
  async function deleteAdditionalUser () {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.ADDITIONAL_USER, {
      SessionID: user.SessionID,
      AuthType: 'delete',
      PNumber: user.PNumber,
      FNumber: user.FNumber
    })
    if (result) {
      setter((user) => {
        return {
          CarNum: user.CarNum,
          IsFreeMember: user.IsFreeMember,
          PNumber: user.PNumber,
          SessionID: user.SessionID,
          Telecom: user.Telecom,
          AlreadyRecvSticker: user.AlreadyRecvSticker,
          CarList: user.CarList,
          FNumber: '',
          FreeExpireDT: user.FreeExpireDT,
          FTelecom: 0,
          GetCouponDT: user.GetCouponDT,
          MemberType: user.MemberType,
          RegDT: user.RegDT,
          Switch: user.Switch
        }
      })
      loading.off()
      return true
    } else {
      loading.off()
      return false
    }
  }
  return {
    add: addAdditionalUser,
    delete: deleteAdditionalUser
  }
}
const useQNA = () => {
  const api = useAPI()
  const loading = useLoading()
  const modal = useModal()
  const qnaData = useRecoilValue(withQNAForm)
  async function requestRegistQNA () {
    loading.on()
    const result = await api.requestSRS<unknown>(CMD_TYPE.REGIST_QUESTION, qnaData)
    if (result) {
      modal
        .alert({
          title: '문의사항 접수가 완료되었습니다.',
          contents: '담당자 확인 후 신속히 답변드리도록 하겠습니다.감사합니다.'
        })
        .then(() => {
          window.location.reload()
        })
      loading.off()
    } else {
      loading.off()
    }
  }

  return {
    requestRegistQNA
  }
}
const useSession = (setUser: SetterOrUpdater<ResponseLoginBody>) => {
  const api = useAPI()
  const loading = useLoading()
  const cookie = useCookies()
  const user = useRecoilValue(resLoginUser)
  async function checkSession () {
    loading.on()
    const { PNumber, SessionID } = cookie.getSessionCookie()
    if (!user.PNumber || !user.SessionID) {
      return false
    }
    if (PNumber && SessionID) {
      const data: RequestSessionCheckBody = {
        PNumber,
        SessionID
      }
      const result = await api.requestSRS<ResponseLoginBody>(CMD_TYPE.SESSION_CHECK, data)
      if (result != null) {
        setUser(result)
      } else {
        cookie.logout()
        window.location.reload()
      }
    }
    loading.off()
  }
  return {
    request: checkSession
  }
}
const useQR = (user: ResponseLoginBody) => {
  const api = useAPI()
  const loading = useLoading()
  const setQRUrl = useSetRecoilState(qrUrl)
  async function getQRUrl () {
    if (user.PNumber && user.SessionID) {
      loading.on()
      const data: RequestQRUrlBody = {
        Telecom: user.Telecom,
        PNumber: user.PNumber,
      }
      const result = await api.requestSRS<ResponseQRUrlBody>(CMD_TYPE.QR_URL, data)
      if (result != null) {
        setQRUrl(result)
        loading.off()
        return true
      } else {
        loading.off()
        return false
      }
    } else {
      return false
    }
  }
  return {
    request: getQRUrl
  }
}
const useUser = () => {
  const [user, setUser] = useRecoilState(resLoginUser)

  return {
    session: useSession(setUser),
    sticker: useSticker(user),
    car: useCar(user, setUser),
    fUser: useAdditionalUser(user, setUser),
    qna: useQNA(),
    qr: useQR(user)
  }
}
export default useUser
