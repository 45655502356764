import { useLocation } from 'react-router-dom'
import menuRoutes from 'router/menuRoutes'
import Utils from '../utils'
import {MetaData} from '../router/router'

const useGetPageBanner = () => {
  const locate = useLocation()
  function getPageInfo () {
    const meta = Utils.common.objectDeepCopy(menuRoutes.filter((route) => locate.pathname.includes(route.path))[0].meta) as MetaData
    if (meta.icon === 'qr') {
      meta.title = 'QR코드 인쇄'
    }
    return meta
  }
  return {
    getPageInfo
  }
}

export default useGetPageBanner
