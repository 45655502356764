import {useRecoilValue} from 'recoil'
import {doubleModal} from '../../recoil/common/atom'
import {useRef} from 'react'
import xBtn from '../../assets/img/modal/xbtn.svg'
import AutocallBtn from './AutocallBtn'
import {SimpleComponent} from '../3_Usage/3_1/StickerPrintBtn'
import ReactToPrint from 'react-to-print'

const DoubleModal = () => {
  const {
    visible,
    title,
    type,
    contents,
    onCancel,
    onConfirm,
    cancelBtnText = '취소',
    confirmBtnText = '확인',
    isXBtn = false,
    footerText = '',
    isBgCancel = true,
  } = useRecoilValue(doubleModal)
  const componentRef = useRef(null)
  console.log(doubleModal)
  return (
    <>
      {visible && (
        <div className='modal-container'>
          <div
            className='modal-bg'
            onClick={() => {
              isBgCancel && onCancel()
            }}
          />
          <div className='modal-contents'>
            {isXBtn && (
              <button className='close-btn' onClick={onCancel}>
                <img src={xBtn} alt='cancel btn' />
              </button>
            )}
            <div className='modal-header'>
              <h1 className='modal-title'>{title}</h1>
            </div>
            <div className='modal-body'>{contents}</div>
            <div className='modal-footer'>
              {title === '가입된 회원이 아닙니다.'
                ? (
                  <div className='confirm'>
                    <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                    <span className='padding_span' />
                    <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                  </div>
                )
                : (
                  <>
                    {title === '인증번호가 발송되었습니다.' || title === '휴대폰 번호를 올바르게 입력해 주세요.'
                      ? (
                        <AutocallBtn type='nomal2' title='확인' onClickFunc={onConfirm} />
                      )
                      : (
                        <div className='button-container'>
                          {type === 'confirm'
                            ? (
                              <>
                                <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                                {title === '서비스 가입이 완료되었습니다'
                                  ? (
                                    <AutocallBtn type='nomal2' title='마이페이지 이동' onClickFunc={onConfirm} />
                                  )
                                  : (
                                    <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                                  )}
                              </>
                            )
                            : (
                              <>
                                {type === 'form'
                                  ? (
                                    <>
                                      <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                                      <SimpleComponent printRef={componentRef} />
                                      <ReactToPrint
                                        trigger={() => (
                                          <button className='print_btn'>
                                            <span>인쇄</span>
                                          </button>
                                        )}
                                        content={() => componentRef.current}
                                      />
                                    </>
                                  )
                                  : (
                                    <>
                                      {type === 'alert'
                                        ? (
                                          <>
                                            {title === '혜택 신청이 완료되었습니다.'
                                              ? (
                                                <AutocallBtn type='nomal2' title='마이페이지 이동' onClickFunc={onConfirm} />
                                              )
                                              : (
                                                <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                                              )}
                                          </>
                                        )
                                        : (
                                          <AutocallBtn type='nomal' title={confirmBtnText} onClickFunc={onConfirm} />
                                        )}
                                    </>
                                  )}
                              </>
                            )}
                        </div>
                      )}
                  </>
                )}
              {footerText && <div className='under-button-text'>{footerText}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default DoubleModal