import {useAPI, useLoading, useModal} from './index'
import {CMD_TYPE} from '../service/constants/common'
import {useRecoilValue, useSetRecoilState} from 'recoil'
import {refundCaptchaErr, refundCapthca, refundLGTerms} from '../recoil/user/atom'
import {withCaptchaForm, withRefundForm} from '../recoil/user/withRefund'
import {otpTimeOut, otpTransctionID} from '../recoil/otp/atom'

export const useRefundAPI = () => {
  const loading = useLoading()
  const api = useAPI()
  const setRefundTerms = useSetRecoilState(refundLGTerms)
  //캡차 인증 리코일
  const setRefundCaptcha = useSetRecoilState(refundCapthca)
  const captchaForm = useRecoilValue(withCaptchaForm)
  const setOtpTimeOut = useSetRecoilState(otpTimeOut)
  const setOtpTransactionID = useSetRecoilState(otpTransctionID)
  const refundForm = useRecoilValue(withRefundForm)
  const setRefundCaptchaErrMsg = useSetRecoilState(refundCaptchaErr)
  const modal = useModal()
  //환불 등록 리코일

  //약관 요청
  async function sendTerms() {
    loading.on()
    const result = await api.requestSRS<ResponseRefundTermsBody>(CMD_TYPE.LGUP_REFUND_TERMS, {})
    if (result) {
      loading.off()
      setRefundTerms(result)
      return true
    } else {
      loading.off()
      return false
    }
  }

  //캡차 생성
  async function generateCaptcha() {
    loading.on()
    const result = await api.requestSRS<ResponseRefundCaptchaBody>(CMD_TYPE.CREATE_CAPTCHA, {})
    if (result) {
      loading.off()
      setRefundCaptcha(result)
      return result
    } else {
      loading.off()
      return {
        CaptchaID: '',
        ImgB64:	'',
      } as ResponseRefundCaptchaBody
    }
  }
//캡차 인증
  async function certCaptcha(authToken: string, CaptchaTransID: string) {
    loading.on()
    const data: RequestRefundOtpBody = {
      CaptchaTransID:	CaptchaTransID,
      Telecom:	captchaForm.Telecom,
      PNumber:	captchaForm.PNumber,
      AuthType:	captchaForm.AuthType,
      AuthToken:	authToken, // captcha 번호
      PCode:	captchaForm.PCode,
    }
    const result = await api.requestSRS<ResponseRefundOtpBody | string>(CMD_TYPE.LGUP_REFUND_CAPTCHA, data)
    if (result) {
      if (typeof(result) !== 'string') {
        loading.off()
        setOtpTimeOut(result.TimeOut)
        setOtpTransactionID(result.TransactionID)
        setRefundCaptchaErrMsg('')
        modal.alert({
          title: '',
          contents: '인증번호가 성공적으로 전송됐습니다.'
        })
        return true
      } else {
        loading.off()
        return false
      }
    } else {
      loading.off()
      return false
    }
  }
//환불 등록

  async function sendRefund() {
    loading.on()
    const result = await api.requestSRS<ResponseRefundRegistBody>(CMD_TYPE.LGUP_REFUND_REG, refundForm)
    if (result) {
      loading.off()
      modal.alert({
        title: '서비스 해지 및 환불 접수 완료',
        contents: `이용해 주셔서 감사합니다.\n\n${result.Amount}원이 영업일 7일 이내 입금될 예정입니다.\n※ 계좌번호, 예금주 등 입력하신 환불정보에 문제가 있을 경우 환불이 지연될 수 있습니다.\n\n입력하신 정보: ${result.BankName}/${result.Accountnumber}/${result.AccountName}`,
        isXBtn: false
      }).then(()=>window.location.reload())
      return true
    } else {
      loading.off()
      return false
    }
  }

  return {
    terms: sendTerms,
    generate: generateCaptcha,
    cert: certCaptcha,
    send: sendRefund,
  }
}