import { selector } from 'recoil'
import {
  refundAgreeRefund, refundBankContentsType, refundBantAccount,
  refundCapthca, refundUName
} from './atom'
import {otpAuthType, otpCryptoNumber, otpPNumber, otpTelecom, otpTransctionID} from '../otp/atom'
import {bankOptions} from '../../components/5_Support/5_5/Refund'

export const withCaptchaForm = selector<Omit<RequestRefundOtpBody, 'AuthToken'>>({
  key: 'withCaptchaForm',
  get: ({ get }) => {
    return {
      CaptchaTransID:	get(refundCapthca).CaptchaID,
      Telecom:	get(otpTelecom),
      PNumber:	get(otpPNumber),
      AuthType:	get(otpAuthType),
      PCode:	'autocall_homepage',
    }
  }
})

const withReqRefundData = selector<ReqRefundData>({
  key: 'withReqRefundData',
  get: ({ get }) => {
    return {
      BankName: bankOptions[get(refundBankContentsType)].label,
      Accountnumber: get(refundBantAccount),
      AccountName: get(refundUName),
      TermsAgree: get(refundAgreeRefund) === 0 ? false : true
    }
  }
})

export const withRefundForm = selector<RequestRefundRegistBody>({
  key: 'withRefundForm',
  get: ({ get }) => {
    return {
      CaptchaTransID:	get(refundCapthca).CaptchaID,
      Telecom:	get(otpTelecom),
      PNumber:	get(otpPNumber),
      TransactionID:	get(otpTransctionID),
      CryptoNumber:	get(otpCryptoNumber),
      PCode:	'autocall_homepage',
      ReqRefundData:	get(withReqRefundData)
    }
  }
})