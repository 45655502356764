import { MenuRoute } from './router'

const menuRoutes: MenuRoute[] = [
  {
    path: 'join',
    meta: {
      display: ['header', 'mainBanner'],
      auth: false,
      title: '서비스 가입',
      sub: '대표번호를 이용한 운전자 호출 서비스<br>오토콜 서비스에 가입하고 개인정보를 보호하세요.',
      icon: 'join'
    }
  },
  {
    path: 'b2b',
    meta: {
      display: [],
      auth: false,
      title: '',
      sub: '',
      icon: ''
    }
  },

  {
    path: 'success',
    meta: {
      auth: false,
      title: '신규 가입자 혜택 신청',
      sub: '혜택 신청 후 서비스 1개월 유지 시 <br/> 오토콜 무점착 스티커를 보내드립니다.',
      icon: 'join'
    }
  },
  {
    path: 'usage',
    meta: {
      display: ['header', 'mainBanner', 'floating'],
      auth: false,
      title: '서비스 이용안내',
      sub: '오토콜 이용방법을 안내해 드려요.',
      icon: 'usage'
    }
  },
  {
    path: 'sticker',
    meta: {
      display: ['header', 'mainBanner', 'floating'],
      auth: false,
      title: '오토콜 주차스티커',
      sub: '오토콜의 무점착 주차스티커를 사용해 보세요!',
      icon: 'sticker'
    }
  },
  {
    path: 'qr',
    meta: {
      display: ['header', 'mainBanner', 'floating'],
      // auth: true,
      auth: false,
      title: 'QR인쇄',
      sub: '원하는 디자인으로 인쇄해 차량에 부착하세요.<br />호출자의 요청 시 호출 문자를 발송해 드립니다.',
      icon: 'qr'
    }
  },
  {
    path: 'notice',
    meta: {
      display: ['header'],
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  {
    path: 'notice/noticeDetail',
    meta: {
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  {
    path: 'news',
    meta: {
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  {
    path: 'faq',
    meta: {
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  {
    path: 'qna',
    meta: {
      display: ['floating'],
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  {
    path: 'refund',
    meta: {
      display: ['floating'],
      auth: false,
      title: '고객지원',
      sub: '오토콜에게 궁금한게 있으신가요?',
      icon: 'notice'
    }
  },
  // {
  //   path: "login",
  //   meta: {
  //     auth: false,
  //     title: "마이페이지",
  //     sub: "가입정보 확인 및 추가 운전자를 등록할 수 있습니다.",
  //     icon: "mypage",
  //   },
  // },
  {
    path: 'mypage',
    meta: {
      display: ['mainBanner', 'floating'],
      auth: true,
      title: '마이페이지',
      sub: '가입정보 확인 및 추가 운전자를 등록할 수 있습니다.',
      icon: 'mypage'
    }
  },
  {
    path: 'cancel',
    meta: {
      display: ['header'],
      auth: false,
      title: '서비스 해지',
      sub: '서비스 해지를 위해 휴대폰 인증이 필요합니다.',
      icon: 'cancel'
    }
  },
  {
    path: 'terms',
    meta: {
      auth: false,
      title: '개인정보처리방침'
    }
  },
]
export default menuRoutes
