import { atomFamily } from 'recoil'
export enum ErrMsgIndex {
  SUCCESS_SEND = 100,
  SUCCESS_CERT = 101,
  SUCCESS_INSERT_CARNUM = 102,
  SUCCESS_RIGHT_COUPONNUM = 103,
  ERR_PNUM_TELECOM = 200,
  ERR_SENDED_CERT = 201,
  ERR_TIMEOUT = 202,
  ERR_WRONG_CERT = 203,
  ERR_OVER_CERTED = 204,
  ERR_PLZ_CERT = 205,
  ERR_RIGHT_CARNUM = 206,
  ERR_NOT_MATCH_CARNUM = 207,
  ERR_REGISTED_CARNUM = 208,
  ERR_WRONG_COUPONNUM = 209,
  ERR_ESSENTINAL_TERMSAGREE = 210,
}
export interface FormErrMsgType {
  [index: number]: string
}
export const FormErrMsg: FormErrMsgType = {
  // success
  100: '인증번호가 전송되었습니다.',
  101: '인증이 완료되었습니다.',
  102: '차량번호 입력이 완료되었습니다.',
  103: '유효한 쿠폰번호 입니다.',
  // fail
  200: '전화번호 및 통신사를 다시 확인해 주세요.',
  201: '3분 내 동일번호 발송 내역이 있습니다. 잠시 후 다시 시도해 주세요.',
  202: '유효시간이 초과되었습니다. 인증번호를 다시 받아주세요.',
  203: '인증번호를 잘못 입력하였습니다.',
  204: '인증번호오류가 3회 발생하였습니다. 인증번호를 다시 받아주세요.',
  205: '휴대폰 번호 인증을 완료해 주세요.',
  206: '올바른 차량번호 형식으로 입력해 주세요.',
  207: '차량번호가 일치하지 않습니다.',
  208: '이미 등록된 차량입니다. 고객센터 1566-7531로 연락주시면 신속히 해결해 드리겠습니다.',
  209: '유효하지 않은 쿠폰입니다. 쿠폰 번호를 정확히 입력해 주세요.',
  210: '필수약관에 동의해 주세요.'
}
interface ServerErrMsgType {
  [index: string]: string
}
export const ServerErrMsg: ServerErrMsgType = {
  // success
  100: '인증번호가 전송되었습니다.',
  101: '인증이 완료되었습니다.',
  102: '차량번호 입력이 완료되었습니다.',
  103: '유효한 쿠폰번호 입니다.',
  // fail
  200: '전화번호 및 통신사를 다시 확인해 주세요.',
  201: '3분 내 동일번호 발송 내역이 있습니다. 잠시 후 다시 시도해 주세요.',
  202: '유효시간이 초과되었습니다. 인증번호를 다시 받아주세요.',
  203: '인증번호를 잘못 입력하였습니다.',
  204: '인증번호오류가 3회 초과하였습니다. 인증번호를 다시 받아주세요.',
  205: '휴대폰 번호 인증을 완료해 주세요.',
  206: '올바른 차량번호 형식으로 입력해 주세요.',
  207: '차량번호가 일치하지 않습니다.',
  208: '이미 등록된 차량입니다. 고객센터 1566-7531로 연락주시면 신속히 해결해 드리겠습니다.',
  209: '유효하지 않은 쿠폰입니다. 쿠폰 번호를 정확히 입력해 주세요.',
  210: '필수약관에 동의해 주세요.',
  211: '문의유형을 선택해 주세요.',
  212: '이름을 입력해 주세요. (2~10자)',
  212.5: '이름을 정확히 입력해주세요',
  213: '연락처를 입력해 주세요.',
  213.5: '휴대폰 번호를 입력해 주세요.',
  214: '연락처를 확인해 주세요.',
  214.5: '올바른 휴대폰 번호를 입력해 주세요.',
  215: '이메일을 입력해 주세요.',
  216: '이메일 형식으로 입력해 주세요.',
  217: '제목을 입력해 주세요.',
  218: '문의 내용을 입력해 주세요',
  219: '문의 응대를 위한 개인정보 이용약관에 동의해 주세요.',
  220: '배송지를 입력해 주세요.',
  221: '가입된 회원이 아닙니다. 휴대폰번호를 정확히 입력해 주세요.',
  222: '알 수 없는 오류 발생. 페이지를 새로고침 해주세요.',
  223: '은행을 선택해주세요.',
  224: '계좌번호를 정확히 입력해주세요.',
  225: '서비스 환불을 위한 개인정보 이용약관에 동의해 주세요.',
  '000000': '정상',
  '030001': '메시지 없음',
  '030002': '이미 가입되어 있습니다.',
  '030003':
    '이미 등록된 차량번호 입니다. 다시 입력해 주세요 만약 차량번호 등록이 안될 경우 오토콜 고객센터 1566-7531로 연락 주시면 신속히 해결해 드리겠습니다.',
  '030004': '차단된 휴대번호 입니다.',
  '030005': '이미 인증 문자를 발송하였습니다.',
  '030006': '아이폰 사용자입니다.',
  '030007': '인증번호오류가 3회 발생하였습니다.\n 인증번호를 다시 받아주세요.',
  '030009': '인증번호 발송 내역이 없습니다.',
  '030010': '로그인 인증 시간이 만료되었습니다.',
  '030011': '로그인 정보가 없습니다.',
  '030012': '잘못된 전화번호를 입력하였습니다.',
  '030013': '잘못된 차량번호를 입력하였습니다.',
  '030014': '보안문자를 잘못 입력 하였습니다. (%d/3)',
  '030015': '입력시간이 초과 되었습니다.',
  '030016': '보안문자 오류가 초과 되었습니다. 다시 진행하세요.',
  '030017': '요청 정보가 없습니다. 다시 진행하세요.',
  '030018': '이미 오토콜에 가입한 가입자 입니다. 추가 운전자 등록은 비가입자만 가능합니다',
  '030019':
    '스티커는 최초 발급 후 재신청하실 수 없습니다.\n부득이하게 재신청이 필요하신 경우 오토콜 고객센터 1566-7531로 문의 부탁드립니다',
  '030020': '이미 추가등록자로 등록되어있는 번호 입니다.',
  '030021': '전화번호 및 통신사를 다시 확인해 주세요.',
  '030022': '오토콜 서비스는 SKT 통신사만 이용 가능한 서비스 입니다.',
  '030023': '이동 통신사 가입에 실패하였습니다 잠시 뒤 다시 시도해 주세요.',
  '030024': '사용 불가능한 쿠폰코드입니다.',
  '030025': '결재 연동중 오류가 발생하였습니다. 잠시 뒤 다시 시도해 주세요.',
  '030026': '결재 연동중 오류가 발생하였습니다. 잠시 뒤 다시 시도해 주세요.',
  '030027': '페이레터 오류 메시지도 페이레터에서 전달',
  '030028': '페이레터 전화번호와 오토콜 전화번호가 다릅니다. 다시 확인해 주세요.',
  '030029': '미성년자는 가입 할 수 없는 서비스입니다.',
  '030030': '이동 통신사 해지에 실패하였습니다 잠시 뒤 다시 시도해 주세요.',
  '030031': '오토콜 서비스는 SKT, LGU+ 통신사만 이용 가능한 서비스 입니다.',
  '030032': '통신사를 확인해 주세요.',
  '030033': '이미 다른 쿠폰을 사용 하였습니다. 쿠폰은 하나의 쿠폰만 사용가능합니다.',
  '030034': '오토콜 서비스는 SKT KT 통신사만 이용 가능한 서비스 입니다.',
  '030035':
    '오토콜 서비스에 가입 불가능한 국번입니다.\n부득이하게 필요하신 경우 오토콜 고객센터 1566-7531로 문의 부탁드립니다',
  '030036': '사용자 정보를 찾을 수 없습니다.',
  '030037': '추가 사용자 정보를 찾을 수 없습니다.\n다시 확인해 주세요.',
  '030038':
    '이미 주차스티커를 받은 전화번호입니다.\n부득이하게 재신청이 필요하신 경우 오토콜 고객센터 1566-7531로 문의 부탁드립니다',
  '030039': '잠시 뒤 다시 시도해 주세요.',
  '030045': '고객센터(1566-7531)로 문의해주세요.',
  '030046': '서비스를 이용하신 이력(리워드, 기타 서비스 이용 등)이 있습니다.\n고객센터(1566-7531)로 문의해주세요.',
  '030047': '서비스 해지가 완료되었습니다. 가입 당일 해지 고객은 요금이 부과되지 않습니다.',
}
export interface ValidateType {
  [index: string]: boolean | string
  valid: boolean
  msg: string
}
export interface DisabledType {
  isDisabled: boolean
}
export const validateTypeFam = atomFamily<ValidateType, string>({
  key: 'validateTypeFam',
  default: (id) => ({
    id,
    valid: false,
    msg: ''
  })
})
export const btnDisabled = atomFamily<DisabledType, string>({
  key: 'btnDisabled',
  default: (id) => ({
    id,
    isDisabled: true
  })
})
export const inputDisabled = atomFamily<DisabledType, string>({
  key: 'inputDisabled',
  default: (id) => ({
    id,
    isDisabled: false
  })
})
