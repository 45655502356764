import styled from 'styled-components'
import STYLE_GUIDE from '../../service/constants/styleGuide'

export const HeaderContainer = styled.header`
   {
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      align-items: center;
      justify-content: center;
      display: flex;
      width: ${STYLE_GUIDE.SIZE.maxMainPageSize};
      margin: 0 auto;
      height: 80px;
      font-family: "GmarketSansMedium", serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
      .header-contents {
        width: 100%;
        max-width: 1920px;
        height: 40px;
        margin: 0;
        display: flex;

        .img-container {
          width: fit-content;
          height: fit-content;
          margin: auto auto auto 0;
          cursor: pointer;

          &:hover {
            opacity: 0.7;
          }

          img {
            width: 156px;
            height: 38px;
            margin-right: 57px;
          }
        }

        .header-nav-item {
          margin: auto 15px;
          color: black;
          font-weight: 500;
          font-family: GmarketSansMedium, serif;
          font-size: 16px;
            
          &:hover {
            color: ${STYLE_GUIDE.COLOR.main01};
          }

          &.select {
            color: black;

            &:hover {
              color: ${STYLE_GUIDE.COLOR.main01};
            }
          }

          &.active {
            color: ${STYLE_GUIDE.COLOR.main01};
          }
        }

        .header-nav-item-mobile {
          display: none;
        }

        .header-mypage-btn {
          font-family: GmarketSansMedium, serif;
          width: 124px;
          height: 40px;
          border-radius: 121px;
          border: none;
          color: #fff;
          background-color: ${STYLE_GUIDE.COLOR.main01};
          text-align: center;
          line-height: 40px;
          font-size: 14px;
          margin-left: 57px;
            
          &:hover {
            background-color: ${STYLE_GUIDE.COLOR.main01};
          }

          &.select {
            background-color: ${STYLE_GUIDE.COLOR.main01};

            &:hover {
              background-color: ${STYLE_GUIDE.COLOR.main01};
            }
          }

          &.active {
            background-color: ${STYLE_GUIDE.COLOR.main01};
            font-weight: 700;
          }
        }

        #navInput {
          display: none;
        }

        #nav {
          display: none;
        }

        .hambugerbtn {
          display: none;
        }
      }
    }
    //모바일
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 100%;
      height: 60px;
      .header-contents {
        width: 100%;
        max-width: 1200px;
        height: 60px;
        margin: 0 0 0 16px;
        display: flex;

        .img-container {
          width: 85px;
          height: 20px;
          margin: auto auto auto 0;
          cursor: pointer;

          img {
            width: 116px;
            height: 28px;
          }
        }

        .header-nav-item {
          display: none;
          margin: auto 32px;
          color: black;

          &:hover {
            color: ${STYLE_GUIDE.COLOR.main01};
          }

          &.select {
            color: ${STYLE_GUIDE.COLOR.main01};

            &:hover {
              color: ${STYLE_GUIDE.COLOR.main01};
            }
          }

          &.active {
            color: black;
            font-weight: 700;
          }
        }

        .header-nav-item-mobile {
          background: ${STYLE_GUIDE.COLOR.main01};
          border-radius: 121px;
          color: ${STYLE_GUIDE.COLOR.white};
          margin: 17px 14px;
          width: 82px;
          align-items: center;
          height: 26px;
          vertical-align: center;
          text-align: center;
          > span {
            font-weight: 500;
            font-size: 12px;
            font-family: GmarketSansMedium, serif;
            line-height: 26px;
            //padding: 0 8px;
          }

          &.active {
            color: white;
          }
        }

        .header-mypage-btn {
          display: none;
          width: 124px;
          height: 40px;
          border-radius: 121px;
          border: none;
          color: #fff;
          background-color: ${STYLE_GUIDE.COLOR.main01};
          text-align: center;
          line-height: 2.5;

          &:hover {
            background-color: ${STYLE_GUIDE.COLOR.main01};
          }

          &.select {
            background-color: ${STYLE_GUIDE.COLOR.main01};

            &:hover {
              background-color: ${STYLE_GUIDE.COLOR.main01};
            }
          }

          &.active {
            background-color: ${STYLE_GUIDE.COLOR.main01};
            font-weight: 700;
          }
        }

        #navInput {
          display: none;
          &:checked ~ .hambugerbtn {
            right: 300px;
            &::before {
              z-index: 998;
              content: "";
              position: fixed;
              top: 0;
              left: 0;
              width: 100vw;
              height: 100vh;
              background: rgba(0, 0, 0, 0.7);
              backdrop-filter: blur(4px);
              -webkit-backdrop-filter: blur(4px);
            }
          }

          &:checked ~ #nav {
            transform: translateX(-300px);
            z-index: 999;
            overflow-y: scroll;
          }
        }

        .hambugerbtn {
          margin: auto 20px auto 0;
          cursor: pointer;

          div {
            width: 25px;
            height: 3px;
            margin: 5px 0;
            border-radius: 5px;
            background: #000;
            transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
          }
        }

        #nav {
          > ul {
            list-style: none;
            > li {
              > a {
                > label {
                  cursor: pointer;
                }
              }
            }
          }

          position: fixed;
          top: 0;
          right: -300px;
          width: 220px;
          height: 100%;

          background: #ffffff;
          transition: 0.5s;
          z-index: 10;

          .textbtn {
            display: flex;
            height: 18px;
            width: 114px;
            border: 1px solid white;
            margin-bottom: -43px;
            z-index: 999;
          }

          .Xbtn {
            display: block;
            width: 24px;
            height: 24px;
            margin: 20px 20px 64px auto;

            img {
              width: 24px;
            }
          }

          .drawer-nav-item {
            display: flex;
            .text {
              margin: auto 10px;
            }
          }

          #nav-menu,
          #nav-menu2 {
            display: none;

            &:checked ~ .nav-menu {
              // color: ${STYLE_GUIDE.COLOR.main01};

              img {
                transform: rotate(-180deg);
              }
            }

            &:checked ~ .sub-menu-ul {
              display: block;
            }

            &:checked ~ .nav-menu2 {
              color: ${STYLE_GUIDE.COLOR.main01};

              img {
                transform: rotate(-180deg);
              }
            }

            &:checked ~ .sub-menu-ul {
              display: block;
            }
          }

          .nav-menu,
          .nav-menu2 {
            cursor: pointer;
            display: flex;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            color: ${STYLE_GUIDE.COLOR.grey01};
            margin: 25px auto 25px 0;

            img {
              margin: auto 30px auto auto;
              transition: 0.5s;
            }
          }

          a {
            width: fit-content;
            margin: 25px auto 25px 0;
            display: block;
            color: ${STYLE_GUIDE.COLOR.grey01};
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;

            &.sub-menu {
              font-weight: 400;
              font-size: 16px;
              line-height: 16px;
              color: #a8b1b3;
              > label {
                cursor: pointer;
              }
            }

            &.active {
              color: ${STYLE_GUIDE.COLOR.main01};
            }
          }

          .sub-menu-ul {
            padding-left: 10px;

            > li {
              list-style: none;
            }

            display: none;
            transition: 0.5s;
          }
        }
      }
    }
  }
`
