import STYLE_GUIDE from '../../../service/constants/styleGuide'
import styled from 'styled-components'
import qrSampleImage from 'assets/img/qr/qrSampleImage.png'
import {useRecoilValue} from 'recoil'
import {qrUrl} from '../../../recoil/user/atom'

const SmallTypeWrap = styled.div`
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 110px;
        height: 110px;
        cursor: pointer;
        position: relative;

        .backgroundImg {
            width: 110px;
            height: 110px;
        }

        .backgroundImgMobile {
            display: none;
        }

        .qrImg1 {
            position: absolute;
            width: 40px;
            height: 40px;
            margin-top: 51px;
            right: 17px;
        }

        .qrImg2 {
            position: absolute;
            width: 40px;
            height: 40px;
            margin-top: 53px;
            right: 18px;
        }

        .qrImg3 {
            position: absolute;
            width: 40px;
            height: 40px;
            margin-top: 18px;
            right: 18px;
        }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 58px;
        height: 58px;
        cursor: pointer;
        position: relative;

        .backgroundImg {
            display: none;
        }

        .backgroundImgMobile {
            width: 58px;
            height: 58px;
        }

        .qrImg1 {
            position: absolute;
            width: 21px;
            height: 21px;
            margin-top: 26.5px;
            right: 9.5px;
        }

        .qrImg2 {
            position: absolute;
            width: 21px;
            height: 21px;
            margin-top: 28px;
            right: 9.5px;
        }

        .qrImg3 {
            position: absolute;
            width: 21px;
            height: 21px;
            margin-top: 10px;
            right: 9.5px;
        }
    }
`

const SmallType = ({urlBackground, checked, handleOnClick} : {urlBackground: QRImage, checked: number, handleOnClick: (index: number) => void}) => {
  const qrImg = useRecoilValue(qrUrl).URL
  return (
    <SmallTypeWrap onClick={() => handleOnClick(urlBackground.idx)} key={urlBackground.idx}>
      <img className='backgroundImg' src={urlBackground.idx === checked ? urlBackground.imgChecked : urlBackground.img}
           alt={urlBackground.alt}/>
      <img className='backgroundImgMobile' src={urlBackground.idx === checked ? urlBackground.imgCheckedMobile : urlBackground.img}
           alt={urlBackground.alt}/>
      <img className={urlBackground.idx === 0 ? 'qrImg1' : urlBackground.idx === 1 ? 'qrImg2' : 'qrImg3'}
           src={qrImg === '' ? qrSampleImage : qrImg} alt={urlBackground.alt}/>
    </SmallTypeWrap>
  )
}

export default SmallType;