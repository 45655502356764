import QRFavorSection from '../../components/8_QR/QRFavorSection'
import QRImageSection from '../../components/8_QR/QRImageSection'
import {useUser} from '../../hooks'

const QR = () => {
  useUser().qr.request().then()

  return (
    <>
      <QRImageSection />
      <QRFavorSection />
    </>
  )
}

export default QR