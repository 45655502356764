import driver1 from 'assets/img/mainbanner/driver-01.png'
import driver2 from 'assets/img/mainbanner/driver-02.png'
import driver3 from 'assets/img/mainbanner/driver-03.png'
import driver4 from 'assets/img/mainbanner/driver-04.png'
import caller1 from 'assets/img/mainbanner/caller-01.png'
import caller2 from 'assets/img/mainbanner/caller-02.png'
import caller3 from 'assets/img/mainbanner/caller-03.png'
import caller4 from 'assets/img/mainbanner/caller-04.png'

import { useState } from 'react'

import { Main4How } from '../../styles/Web/Main/main4how_to_styled'

interface TabContent {
  img: string
  pc_text: string
  title: string
  mo_text: string
}
interface AutocallTab {
  name: string
  content: TabContent[]
}
interface TabActiveState {
  tabIdx: number
  contentIdx: number
}

const Main4HowToSection = () => {
  const PhoneType = navigator.userAgent.toLowerCase()
  const [tab, setTab] = useState<TabActiveState>({
    tabIdx: 0,
    contentIdx: 0
  })
  const tabData: AutocallTab[] = [
    {
      name: '운전자',
      content: [
        {
          title: '서비스 가입',
          img: driver1,
          pc_text: '오토콜 홈페이지에서 휴대폰 인증을 하고<br> 서비스에 가입해 주세요.',
          mo_text: '오토콜 홈페이지에서 <br> 휴대폰 인증을 하고 서비스에 <br>가입해 주세요.'
        },
        {
          title: '주차번호판 교체',
          img: driver2,
          pc_text: '기존 주차번호판의 개인 연락처를 <br> 1566-5382로 교체해 주세요.',
          mo_text: '기존 주차번호판의 개인<br> 연락처를 1566-5382로 <br>교체해 주세요.'
        },
        {
          title: '호출자 연결',
          img: driver3,
          pc_text: '서비스 가입 시 등록된 차량번호와 매칭되어,<br> 호출자의 요청을 알려드립니다.',
          mo_text: '서비스 가입 시 등록된 차량번호<br>와 매칭되어, 호출자의 요청을 <br>알려드립니다.'
        },
        {
          title: '자세한 이용방법은?',
          img: driver4,
          pc_text: '자세한 이용방법은 홈페이지에 위치한 <br> 서비스 이용안내 메뉴를 확인해 주세요.',
          mo_text: '자세한 이용방법은 홈페이지에 <br>위치한 서비스 이용안내 메뉴를 <br>확인해 주세요.'
        }
      ]
    },
    {
      name: '호출자',
      content: [
        {
          title: '1566-5382',
          img: caller1,
          pc_text: '호출하고자 하는 차량에 부착된 <br> 1566-5382로 전화를 걸어주세요.',
          mo_text: '호출하고자 하는 차량에 부착된 <br> 1566-5382로 전화를 <br>걸어주세요.'
        },
        {
          title: '차량번호 입력',
          img: caller2,
          pc_text: 'ARS 안내 멘트에 따라 호출할 차량번호를<br> 입력해 주세요.',
          mo_text: 'ARS 안내 멘트에 따라 호출할<br> 차량번호를 입력해 주세요.'
        },
        {
          title: '호출요청 완료',
          img: caller3,
          pc_text: "차량번호 확인 후 '호출 요청'을 완료하면 <br> 통화가 종료됩니다.",
          mo_text: "차량번호 확인 후 '호출 요청'을 <br>완료하면  통화가 종료됩니다."
        },
        {
          title: '호출결과 확인',
          img: caller4,
          pc_text: '오토콜이 호출 결과를 휴대폰 문자메세지로 <br> 전달드리오니, 잠시 기다려 주세요.',
          mo_text: '오토콜이 호출 결과를 휴대폰<br> 문자메세지로 전달드리오니, <br>잠시 기다려 주세요.'
        }
      ]
    }
  ]
  const handleClickTabNav = (tabIdx: number) => {
    if (window.screen.width < 1200) {
      if (PhoneType.includes('android')) {
        // android 일 때
        if (tabIdx === 0) {
          window.scrollTo({
            top: 5200,
            behavior: 'smooth'
          })
        } else {
          window.scrollTo({
            top: 7150,
            behavior: 'smooth'
          })
        }
      } else if (PhoneType.includes('iphone')) {
        if (tabIdx === 0) {
          window.scrollTo({
            top: 5280,
            behavior: 'smooth'
          })
        } else {
          window.scrollTo({
            top: 7230,
            behavior: 'smooth'
          })
        }
      }
    } else {
      //2730
      if (tabIdx === 0) {
        window.scrollTo({
          top: 5700,
          behavior: 'smooth'
        })
      } else {
        window.scrollTo({
          top: 8430,
          behavior: 'smooth'
        })
      }
    }
  }

  window.addEventListener('scroll', function () {
    if (window.screen.width < 1200) {
      if (window.scrollY > 7149) {
        setTab({
          tabIdx: 1,
          contentIdx: 0
        })
      } else {
        setTab({
          tabIdx: 0,
          contentIdx: 0
        })
      }
    } else {
      if (window.scrollY > 8429) {
        setTab({
          tabIdx: 1,
          contentIdx: 0
        })
      } else {
        setTab({
          tabIdx: 0,
          contentIdx: 0
        })
      }
    }
  })

  return (
    <Main4How>
      <section>
        <div className='mo_text'>
          <span>오토콜 대표번호로</span>
          <p>
            어떻게 내 번호를
            <br />
            <span className='underline'>보호할 수</span> 있나요?
          </p>
        </div>
        <div className='main_howto_content'>
          <div className='pc_text'>
            <span>오토콜 대표번호로</span>
            <p>
              어떻게 내 번호를
              <br />
              <span className='underline'>보호할 수</span> 있나요?
            </p>
          </div>
          <div className='button_toggle'>
            {tabData.map((t, index) => (
              <button
                key={index}
                className={`tab-nav-item ${tab.tabIdx === index ? 'active' : ''} ${tab.tabIdx === 1 && 'caller'}`}
                onClick={() => handleClickTabNav(index)}
              >
                <span>{t.name}</span>
              </button>
            ))}
          </div>
        </div>
        <div className='img_container'>
          <div className='img_option'>
            {tabData[0].content.map((option, index) => (
              <section key={option.img}>
                <div>
                  <span className='round_idx'>{index + 1}</span>
                  <p className='option_title'>{option.title}</p>
                </div>
                <p className='option_desc_pc' dangerouslySetInnerHTML={{ __html: option.pc_text }} />
                <p className='option_desc_mobile' dangerouslySetInnerHTML={{ __html: option.mo_text }} />
                <img src={option.img} alt='content img' />
              </section>
            ))}
          </div>
          <div className='division' />
          <div className='img_option'>
            {tabData[1].content.map((option, index) => (
              <section key={option.img}>
                <div>
                  <span className='round_idx caller'>{index + 1}</span>
                  <p className='option_title caller'>{option.title}</p>
                </div>
                <p className='option_desc_pc' dangerouslySetInnerHTML={{ __html: option.pc_text }} />
                <p className='option_desc_mobile' dangerouslySetInnerHTML={{ __html: option.mo_text }} />
                <img src={option.img} alt='content img' />
              </section>
            ))}
          </div>
        </div>
      </section>
    </Main4How>
  )
}

export default Main4HowToSection
