import AutocallBtn from './AutocallBtn'
import xBtn from 'assets/img/modal/xbtn.svg'
import { useRecoilValue } from 'recoil'
import { modal } from 'recoil/common/atom'
import ReactToPrint from 'react-to-print'
import { SimpleComponent } from '../3_Usage/3_1/StickerPrintBtn'
import { useRef } from 'react'

const Modal = () => {
  const {
    visible,
    title,
    type,
    contents,
    onCancel,
    onConfirm,
    cancelBtnText = '취소',
    confirmBtnText = '확인',
    isXBtn = false,
    footerText = '',
    isBgCancel = true,
  } = useRecoilValue(modal)
  const componentRef = useRef(null)
  return (
    <>
      {visible && (
        <div className='modal-container'>
          <div
            className='modal-bg'
            onClick={() => {
              isBgCancel && onCancel()
            }}
          />
          <div className='modal-contents'>
            {isXBtn && (
              <button className='close-btn' onClick={onCancel}>
                <img src={xBtn} alt='cancel btn' />
              </button>
            )}
            <div className='modal-header'>
              <h1 className='modal-title'>{title}</h1>
            </div>
            <div className='modal-body'>{contents}</div>
            {(title === '하단 보안문자를 입력하세요.') &&
              <div className='modal-captcha-err'>
                <div className={`warning-text`}>화면에 출력된 문자를 정확하게 입력해 주세요.</div>
              </div>
            }
            <div className='modal-footer'>
              {title === '가입된 회원이 아닙니다.'
                ? (
                  <div className='confirm'>
                    <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                    <span className='padding_span' />
                    <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                  </div>
                  )
                : (
                  <>
                    {title === '인증번호가 발송되었습니다.' || title === '휴대폰 번호를 올바르게 입력해 주세요.'
                      ? (
                        <AutocallBtn type='nomal2' title='확인' onClickFunc={onConfirm} />
                        )
                      : (
                        <div className='button-container'>
                          {type === 'confirm'
                            ? (
                              <>
                                <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                                {title === '서비스 가입이 완료되었습니다'
                  ? (
                                  <AutocallBtn type='nomal2' title='마이페이지 이동' onClickFunc={onConfirm} />
                    )
                  : (
                                  <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                    )}
                              </>
                              )
                            : (
                              <>
                                {type === 'form'
                  ? (
                                  <>
                                    <AutocallBtn type='cancel' title={cancelBtnText} onClickFunc={onCancel} />
                                    <SimpleComponent printRef={componentRef} />
                                    <ReactToPrint
                  trigger={() => (
                                      <button className='print_btn'>
                                        <span>인쇄</span>
                                      </button>
                                    )}
                  content={() => componentRef.current}
                />
                                  </>
                    )
                  : (
                                  <>
                                    {type === 'alert'
                  ? (
                                      <>
                                        {title === '혜택 신청이 완료되었습니다.'
                  ? (
                                          <AutocallBtn type='nomal2' title='마이페이지 이동' onClickFunc={onConfirm} />
                    )
                  : (
                                          <AutocallBtn type='nomal2' title={confirmBtnText} onClickFunc={onConfirm} />
                    )}
                                      </>
                    )
                  : (
                                      <AutocallBtn type='nomal' title={confirmBtnText} onClickFunc={onConfirm} />
                    )}
                                  </>
                    )}
                              </>
                              )}
                        </div>
                        )}
                  </>
                  )}
              {footerText && <div className='under-button-text'>{footerText}</div>}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Modal

/*20231024 lgu+ 가입/해지 불가 모달*/
/*
export const mainService = <div className={'service-modal'}>LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 가입/해지가 일시 중단될 예정입니다.<br/><br/>
  ▣ 점검 기간<br/>
  2023년 10월 20일(금) 22:00 ~ 2023년 10월 23일(월) 08:00<br/><br/>
  자세한 내용은 공지사항 확인을 부탁 드립니다.<br/><br/>
  더 좋은 서비스로 보답하겠습니다.</div>
export const cancelService = <div className={'service-modal'}>LG U+ 이동통신사에서 진행하는 시스템 점검에 의해 LG U+ 이용 고객의 서비스 해지가 일시 중단될 예정입니다.<br/><br/>
  ▣ 점검 기간<br/>
  2023년 10월 20일(금) 22:00 ~ 2023년 10월 23일(월) 08:00<br/><br/>
  자세한 내용은 공지사항 확인을 부탁 드립니다.<br/><br/>
  더 좋은 서비스로 보답하겠습니다.</div>*/
