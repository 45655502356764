import STYLE_GUIDE from 'service/constants/styleGuide'
import styled from 'styled-components'
import MyPageCarInfomation from './MyPageCarInfomation'
import MyPageUserInfomation from './MyPageUserInfomation'
import {useResetRecoilState, useSetRecoilState} from 'recoil'
import {resLoginUser, resStickerHistory} from '../../../recoil/user/atom'
import { useLoginData, useStickerData } from '../../../hooks'
import { useEffect } from 'react'
import useCookies from '../../../hooks/useCookies.hook'
import { useNavigate } from 'react-router-dom'

const MyPageWrap = styled.div`
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin-top: -110px;
  }
`
const MyPageHeader = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 60px auto;
    text-align: left;
    > h1 {
      font-weight: 500;
      font-size: 38px;
      line-height: 38px;
      font-family: GmarketSansBold, serif;
      margin: 0 auto 14px 0;
    }
    .mo {
      display: none;
    }
    > span {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    border-bottom: 1px solid #dfe8ea;
    border-top: 1px solid #dfe8ea;
    padding: 24px 0;
    .pc {
      display: none;
    }
    > h1 {
      font-weight: 500;
      font-size: 26px;
      line-height: 30px;
      font-family: GmarketSansBold, serif;
    }
    > span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${STYLE_GUIDE.COLOR.grey02};
    }
  }
`
const MyPageUserInfoContainer = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 40px auto 125px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: 30px 0 20px 0;
  }
`
const InfoText = styled.div`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    text-align: left;
    > h3 {
      margin: 20px 0 8px 0;
      font-weight: 500;
      font-size: 24px;
      line-height: 24px;
      font-family: GmarketSansBold, serif;
    }
    .mo {
      display: none;
    }
    > p {
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      color: ${STYLE_GUIDE.COLOR.grey02};
      margin: 0 0 20px 0;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    text-align: left;
    .pc {
      display: none;
    }
    > h3 {
      margin: 20px 0 8px 0;
      font-weight: 500;
      font-size: 22px;
      line-height: 26px;
      font-family: GmarketSansBold, serif;
    }
    > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${STYLE_GUIDE.COLOR.grey02};
      margin: 0 0 20px 0;
    }
  }
`

const MyPageContent = () => {
  const setLoginUser = useSetRecoilState(resLoginUser)
  const setStcikerData = useSetRecoilState(resStickerHistory)
  const resetUser = useResetRecoilState(resLoginUser)
  const user = useLoginData()
  const sticker = useStickerData()
  const cookie = useCookies()
  const navigate = useNavigate()
  const { getSessionCookie } = useCookies()
  const session = getSessionCookie()
  useEffect(() => {
    if ((user?.data) != null) {
      user.data && cookie.login(user.data.PNumber, user.data.SessionID, String(user.data.Telecom))
      setLoginUser(user.data)
      ;(sticker.data != null) && setStcikerData(sticker.data)
    } else {
      cookie.login('', '', '')
      resetUser()
    }
  }, [setLoginUser, setStcikerData, user.data, sticker.data, cookie, navigate, session.PNumber, resetUser])

  return (
    <MyPageWrap>
      <MyPageHeader>
        <h1>마이페이지</h1>
        <span className='pc'>가입정보 확인 및 추가 운전자를 등록할 수 있습니다.</span>
        <span className='mo'>
          가입정보 확인 및 추가 운전자를 <br />
          등록할 수 있습니다.
        </span>
      </MyPageHeader>
      <MyPageUserInfoContainer>
        <InfoText>
          <h3>가입 고객 정보</h3>
          <p>추가 운전자 연락처를 등록할 수 있습니다.</p>
        </InfoText>
        <MyPageUserInfomation />
        <InfoText>
          <h3>등록된 차량 정보</h3>
          <p className='pc'>차량번호 등록 및 연락처의 우선순위를 변경할 수 있습니다.</p>
          <p className='mo'>
            차량번호 등록 및 연락처의 우선순위를 <br />
            변경할 수 있습니다.
          </p>
        </InfoText>
        <MyPageCarInfomation />
      </MyPageUserInfoContainer>
    </MyPageWrap>
  )
}

export default MyPageContent
