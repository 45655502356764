import { ReturnValidate } from 'hooks/useValidate'
import {KeyboardEvent, RefObject, useCallback, useEffect} from 'react'
import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import {btnDisabled, ValidateType, validateTypeFam} from 'recoil/validate/atom'
import styled from 'styled-components'
import { otpCryptoNumber, otpPNumber } from '../../../recoil/otp/atom'
import { useValidate } from '../../../hooks'
import { useLocation } from 'react-router-dom'
import { httpData, restServer } from '../../../service/apis/api'
import { CMD_TYPE } from '../../../service/constants/common'
import { otpWithSendReset } from 'recoil/otp/withSendAndCert'

const AutocallTextarea = styled.textarea`
  height: 260px;
  padding: 10px;
  resize: none;
  margin: 220px 10px 0 10px;

  &:focus {
    outline: none;
  }
`
type InputTagType = 'text' | 'number' | 'tel' | 'textarea' | 'email'

interface InputProps {
  inputType: InputTagType
  validate: ReturnValidate<ValidateType>
  state: string
  setState: SetterOrUpdater<string>
  placeholder: string
  rows?: number
  isCount?: boolean
  maxLength?: number
  timeRef?: number
  replacer?: (str: string) => string
  onKeyup?: () => void
  inputRef?: RefObject<HTMLInputElement>
}

const AutocallInput = ({
  inputType = 'text',
  state,
  setState,
  validate,
  placeholder,
  rows = 10,
  isCount = false,
  maxLength,
  timeRef = 0,
  replacer = (str) => str,
  onKeyup = () => console.log('enter'),
  inputRef
}: InputProps) => {
  const { recoilState, validFunc, inputDisabled = { isDisabled: false } } = validate
  const [validState] = recoilState
  const { isDisabled } = inputDisabled
  const pNumber = useRecoilValue(otpPNumber)
  const crypto = useRecoilValue(otpCryptoNumber)
  const curr_path = useLocation()
  const { join } = useValidate()
  const { phoneNumber, certNumber } = join
  const resetSendOTP = useSetRecoilState(otpWithSendReset)
  const setCertBtnDisabled = useSetRecoilState(btnDisabled('certBtn'))
  const certCarNumberValidState = useRecoilState(validateTypeFam('certCarNumber'))
  const handleChange = (value: string) => {
    setState(replacer(value))
  }

  useEffect(() => {
    return () => {
      resetSendOTP((cur) => cur)
    }
  }, [resetSendOTP])
  // console.log(placeholder)
  const handleBlur = async () => {
    if (placeholder === '차량번호 재입력') {
      const data = await restServer(
        httpData(CMD_TYPE.CHECK_CAR_NUMBER, {
          CarNum: state
        })
      )
      if (data.data.Header.ErrCode === '000000') {
        if (state.length !== 0) {
          certCarNumberValidState[1]({ valid: true, msg: '102' })
        }
      } else if (data.data.Header.ErrCode === '030003') {
        certCarNumberValidState[1]({ valid: true, msg: '208' })
      } else {
        certCarNumberValidState[1]({ valid: true, msg: '' })
      }
      // console.log(data)
    }
    validFunc(state)
  }
  const handleOnKeyup = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onKeyup()
    }
  }
  const isErrMsg = (value: string): boolean => {
    return Number(value) >= 200
  }
  useEffect(() => {
    if (timeRef === 1) {
      setTimeout(() => {
        setCertBtnDisabled({ isDisabled: true })
        certNumber.recoilState[1]({ valid: true, msg: '202' })
        phoneNumber.recoilState[1]({ valid: false, msg: '202' })
      }, 500)
    }
  })
  const handleKeyDown = useCallback(
    (event: any) => {
      if (placeholder === 'ex) 01012345678') {
        if (
          event.key === '-' ||
          event.key === '+' ||
          event.key === '.' ||
          event.keyCode === 69 ||
          event.keyCode === 32 ||
          event.keyCode === 37 ||
          event.keyCode === 38 ||
          event.keyCode === 39 ||
          event.keyCode === 40
        ) { event.preventDefault() }
      }
    },
    [placeholder]
  )

  return (
    <div
      className={`input-container 
        ${validState.valid && isErrMsg(validState.msg) ? 'warning' : ''} 
        ${inputType === 'textarea' ? 'textarea' : ''}
        ${placeholder === '‘-’를 제외한 번호를 입력하세요.' && pNumber.length > 9 ? 'complete' : ''}
        ${
          placeholder !== '‘-’를 제외한 번호를 입력하세요.' && inputType === 'tel' && crypto.length === 4
            ? 'complete'
            : ''
        }
        `}
    >
      <div className='input-content'>
        {inputType === 'textarea'
          ? (
            <AutocallTextarea
              rows={rows}
              placeholder={placeholder}
              onBlur={async () => await handleBlur()}
              disabled={isDisabled}
              maxLength={maxLength}
              onChange={(e) => handleChange(e.target.value)}
            />
            )
          : (
            <input
              ref={inputRef}
              type={inputType}
              placeholder={placeholder}
              value={state}
              disabled={isDisabled}
              onBlur={async () => await handleBlur()}
              maxLength={maxLength}
              onChange={(e) => handleChange(e.target.value)}
              onKeyUp={(e) => handleOnKeyup(e)}
              onClick={async () => await handleBlur()}
              onKeyDown={handleKeyDown}
              onInput={(e: any) => {
                if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) }
              }}
              pattern={inputType === "number" ? '[0-9]*' : ''}
            />
            )}
      </div>
      {isCount && (
        <div className={`countdown ${curr_path.pathname === '/join' && 'margin'}`}>
          {timeRef / 60 < 10 ? '0' : ''}
          {Math.floor(timeRef / 60)}:{timeRef % 60 < 10 ? '0' : ''}
          {timeRef % 60}
        </div>
      )}
    </div>
  )
}

export default AutocallInput
