import AutocallBtn from '../../../Common/AutocallBtn'
import {useModal, useValidate} from '../../../../hooks'
import {useRecoilState, useSetRecoilState} from 'recoil'
import {useEffect, useRef, useState} from 'react'
import {otpPNumber, otpTimeOut, otpTransctionID} from '../../../../recoil/otp/atom'
import {useRefundAPI} from '../../../../hooks/useRefundAPI.hook'
import refreshBtn from 'assets/img/refund/refresh.svg'
import {refundCaptchaErr} from '../../../../recoil/user/atom'
import {inputDisabled} from '../../../../recoil/validate/atom'

const RefundCertBtn = () => {
  const modal = useModal()
  const [pNumber, setPNumber] = useRecoilState(otpPNumber)
  const [authToken, setAuthToken] = useState<string>('')
  const { refundPNumberV, refundCaptchaAuthTokenV } = useValidate().refund
  const {validFunc} = refundPNumberV
  const refund = useRefundAPI()
  const authTokenRef = useRef<HTMLInputElement>(null)
  const { replaceCertNumber } = useValidate().replace
  const setRefundCaptchaErr = useSetRecoilState(refundCaptchaErr)
  const setPhoneInput = useSetRecoilState(inputDisabled('phoneInput'))
  const setOtpTimeOut = useSetRecoilState(otpTimeOut)
  const setOtpTransactionID = useSetRecoilState(otpTransctionID)

  useEffect(() => {
    validFunc(pNumber)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pNumber])

  const handleChange = (value: string) => {
    setAuthToken(replaceCertNumber(value))
  }
  const handleBlur = async () => {
    refundCaptchaAuthTokenV.validFunc(authToken)
  }

  const captchaModal = (result: ResponseRefundCaptchaBody) => {
    modal.captcha({
      title: '하단 보안문자를 입력하세요.',
      contents: (
        <div className="captcha_sec">
          <div className="captcha_img_box">
            {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
            <img
              src={`data:image/png;base64,${result.ImgB64}`}
              alt="Base64 Image"
            />
          </div>
          <div className="refresh" onClick={handleRecaptchaModal}>
            <img src={refreshBtn} alt="Refresh"/>
            <span>
              새로고침
            </span>
          </div>
          <input
            ref={authTokenRef}
            type="number"
            placeholder="보안문자를 입력해주세요."
            onBlur={async () => await handleBlur()}
            maxLength={4}
            onChange={(e) => handleChange(e.target.value)}
            onClick={async () => await handleBlur()}
            onInput={(e: any) => {
              if (e.target.value.length > e.target.maxLength) {
                e.target.value = e.target.value.slice(0, e.target.maxLength)
              }
            }}
          />
        </div>
      ),
      isXBtn: false,
      cancelBtnText: '취소',
      confirmBtnText: '확인',
      isBgCancel: false,
      onConfirm: async () => {
        if (authTokenRef.current) {
          await refund.cert(authTokenRef.current.value, result.CaptchaID)
        } else {
          await refund.cert('', result.CaptchaID)
        }
      },
      onCancel: () => {
        setRefundCaptchaErr('')
        setPhoneInput({isDisabled: false})
        setOtpTimeOut(0)
        setOtpTransactionID('')
      }
    }).then(() => {
      // window.location.href = link
    }).catch(() => {
      /** 취소 버튼 눌렀을 때 처리*/
      // console.info('취소')
      setPNumber('')
    })
  }

  const handleRecaptchaModal = async () => {
    await refund.generate().then((result) => {
      captchaModal(result)
    })
  }

  return (
    <AutocallBtn
      title="인증번호 받기"
      type="nomal"
      onClickFunc={handleRecaptchaModal}
      disabled={refundPNumberV.btnDisabled?.isDisabled}
    />
  )
}

export default RefundCertBtn