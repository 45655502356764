import dayjs from 'dayjs'
import {Buffer} from 'buffer'

export interface CommonUtilInterface {
  getGUID: (push?: boolean) => string
  setCookie: (cName: string, cValue: string, time?: number) => void
  getCookie: (cName: string) => string
  deleteCookie: (cName: string) => void
  getDecodeStr: (strData: string) => string
  objectDeepCopy: (obj: object) => object
  parsePNumber: (str: string) => string
}

const CommonUtils: CommonUtilInterface = {
  getGUID (push) {
    const guidFunc = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    if (push) {
      return `${guidFunc()}${guidFunc()}${guidFunc()}${guidFunc()}`
    } else {
      return `${guidFunc()}${guidFunc()}-${guidFunc()}-${guidFunc()}-${guidFunc()}-${guidFunc()}${guidFunc()}${guidFunc()}`
    }
  },
  setCookie: function (cName: string, cValue: string, time?: number): void {
    const timeout = dayjs(Date.now())
      .add(time ?? 1, 'hour')
      .toString()
    // console.log("setcookie timeout ", timeout)
    document.cookie = `${cName}=${cValue};expires=${timeout};"`
  },
  getCookie: function (cName: string): string {
    cName += '='
    const cookieData = document.cookie
    let start = cookieData.indexOf(cName)
    let cValue = ''
    if (start !== -1) {
      start += cName.length
      let end = cookieData.indexOf(';', start)
      if (end === -1) end = cookieData.length
      cValue = cookieData.substring(start, end)
    }
    return unescape(cValue)
  },
  deleteCookie: function (cName: string): void {
    // console.log("delete cookie")
    document.cookie = `${cName}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`
  },
  getDecodeStr: function (strData: string): string {
    return Buffer.from(strData, 'base64').toString('utf-8');
  },
  objectDeepCopy: function (obj: object): object {
    return JSON.parse(JSON.stringify(obj));
  },
  parsePNumber: function (str: string): string {
    let formattedPhone = ''

    if (str.length < 4) {
      formattedPhone = str
    } else if (str.length < 8) {
      formattedPhone = `${str.slice(0, 3)}-${str.slice(3)}`
    } else if (str.length <= 10) {
      formattedPhone = `${str.slice(0, 3)}-${str.slice(
        3,
        6,
      )}-${str.slice(6)}`
    } else {
      formattedPhone = `${str.slice(0, 3)}-${str.slice(
        3,
        7,
      )}-${str.slice(7, 11)}`
    }

    return formattedPhone.length > 0 ? formattedPhone : ''
  }
}

export default CommonUtils
