import {ReactNode} from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil'
import {doubleModal, modal} from 'recoil/common/atom'

interface OnPorps {
  title: string
  contents: ReactNode
  onCancel?: () => void
  onConfirm?: () => void
  cancelBtnText?: string
  confirmBtnText?: string
  footerText?: ReactNode
  isXBtn?: boolean
  isBgCancel?: boolean
}

const useModal = () => {
  const [props, setProps] = useRecoilState(modal)
  const reset = useResetRecoilState(modal)
  const [doubleProps, setDoubleProps] = useRecoilState(doubleModal)
  const resetDouble = useResetRecoilState(doubleModal)
  function bodyScrollLock () {
    document.body.style.overflow = 'hidden'
  }
  function bodyScrollFree () {
    document.body.style.overflow = 'auto'
  }
  async function onAlert ({ title, contents, onCancel = off, onConfirm = off, isXBtn = false }: OnPorps) {
    bodyScrollLock()
    return await new Promise((resolve, reject) => {
      if (!props.visible) {
        setProps({
          visible: true,
          type: 'alert',
          title,
          contents,
          onCancel: () => {
            off()
            reject(onCancel())
          },
          onConfirm: () => {
            off()
            resolve(onConfirm())
          },
          isXBtn
        })
      }
    })
  }
  async function onConfirm ({ title, contents, onCancel = off, onConfirm = off, confirmBtnText, cancelBtnText, isBgCancel = true }: OnPorps) {
    return await new Promise((resolve, reject) => {
      if (!props.visible) {
        setProps({
          visible: true,
          type: 'confirm',
          title,
          contents,
          confirmBtnText,
          cancelBtnText,
          isBgCancel,
          onCancel: () => {
            off()
            reject(onCancel())
          },
          onConfirm: () => {
            off()
            resolve(onConfirm())
          }
        })
      }
    })
  }
  async function onForm ({
    title,
    contents,
    onCancel = off,
    onConfirm = off,
    cancelBtnText,
    confirmBtnText,
    footerText,
    isXBtn
  }: OnPorps) {
    return await new Promise((resolve, reject) => {
      if (!props.visible) {
        setProps({
          visible: true,
          type: 'form',
          title,
          contents,
          onCancel: () => {
            off()
            reject(onCancel())
          },
          onConfirm: () => {
            off()
            resolve(onConfirm())
          },
          cancelBtnText,
          confirmBtnText,
          footerText,
          isXBtn
        })
      }
    })
  }
  function off () {
    bodyScrollFree()
    reset()
  }
  function doubleOff () {
    bodyScrollFree()
    resetDouble()
  }
  async function onCaptcha ({ title, contents, onCancel = off, onConfirm = off, confirmBtnText, cancelBtnText, isBgCancel = true }: OnPorps) {
    return await new Promise((resolve, reject) => {
      if (!props.visible) {
        setProps({
          visible: true,
          type: 'confirm',
          title,
          contents,
          confirmBtnText,
          cancelBtnText,
          isBgCancel,
          onCancel: () => {
            off()
            reject(onCancel())
          },
          onConfirm: () => {
            resolve(onConfirm())
          }
        })
      }
    })
  }
  async function onAlertDouble ({ title, contents, onCancel = doubleOff, onConfirm = doubleOff, isXBtn = false }: OnPorps) {
    bodyScrollLock()
    return await new Promise((resolve, reject) => {
      if (!doubleProps.visible) {
        setDoubleProps({
          visible: true,
          type: 'alert',
          title,
          contents,
          onCancel: () => {
            doubleOff()
            reject(onCancel())
          },
          onConfirm: () => {
            doubleOff()
            resolve(onConfirm())
          },
          isXBtn
        })
      }
    })
  }
  /*20231024 lgu+ 가입/해지 불가 모달*/
  /*const lguServiceConfirmModal = (isMain?: boolean) => {
    onConfirm({
      title: 'LG U+ 시스템 점검 안내',
      contents: isMain ?  mainService: cancelService,
      confirmBtnText: '자세히보기',
      cancelBtnText: '취소',
      onConfirm: () => navigate(`/noticeDetail/${SERVICE_DATE.LINK}`),
    })
  }*/
  return {
    alert: onAlert,
    confirm: onConfirm,
    form: onForm,
    captcha: onCaptcha,
    alertDouble: onAlertDouble,
    off,
  }
}
/*20231024 lgu+ 가입/해지 불가 모달*/
/*export const useServiceModal = (startDt: dayjs.Dayjs, endDt: dayjs.Dayjs, isMain?: boolean) => {
  const today = dayjs(Date.now())
  const { lguServiceConfirmModal } = useModal()
  useEffect(() => {
    if (today.isAfter(startDt) && today.isBefore(endDt)) {
      lguServiceConfirmModal(isMain)
    }
    // eslint-disable-next-line
  }, [])
}*/
export default useModal
