import styled from 'styled-components'
import STYLE_GUIDE from '../../../service/constants/styleGuide'
import qrSampleImage from 'assets/img/qr/qrSampleImage.png'
import {useRecoilValue} from 'recoil'
import {qrUrl} from '../../../recoil/user/atom'

const BigTypeWrap = styled.div`
    @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 490px;
        height: 490px;
        position: relative;

        .backgroundImg {
            width: 490px;
            height: 490px;
        }

        .qrImg1 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 225px;
            right: 75px;
        }

        .qrImg2 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 235px;
            right: 78px;
        }

        .qrImg3 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 80px;
            right: 81px;
        }
    }
    @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
        width: 210px;
        height: 210px;
        position: relative;
        cursor: pointer;

        .backgroundImg {
            width: 210px;
            height: 210px;
        }

        .qrImg1 {
            position: absolute;
            width: 76px;
            height: 76px;
            margin-top: 97px;
            right: 32px;
        }

        .qrImg2 {
            position: absolute;
            width: 76px;
            height: 76px;
            margin-top: 101px;
            right: 35px;
        }

        .qrImg3 {
            position: absolute;
            width: 76px;
            height: 76px;
            margin-top: 35px;
            right: 35px;
        }
    }

    @media print {
        width: 490px;
        height: 490px;
        position: relative;
        top: 50px;

        .backgroundImg {
            width: 490px;
            height: 490px;
        }

        .qrImg1 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 225px;
            right: 75px;
        }

        .qrImg2 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 235px;
            right: 78px;
        }

        .qrImg3 {
            position: absolute;
            width: 178px;
            height: 178px;
            margin-top: 80px;
            right: 81px;
        }
    }
    
`

const BigType = ({urlBackground}:{urlBackground: QRImage}) => {
  const qrImg = useRecoilValue(qrUrl).URL
  return (
    <BigTypeWrap>
      <img className='backgroundImg'
           src={urlBackground.img}
           alt={urlBackground.alt}/>
      <img className={urlBackground.idx === 0 ? 'qrImg1' : urlBackground.idx === 1 ? 'qrImg2' : 'qrImg3'} src={qrImg === '' ? qrSampleImage : qrImg} alt={urlBackground.alt}/>
    </BigTypeWrap>

  )
}

export default BigType