import { ReturnValidate } from 'hooks/useValidate'
import { ReactNode } from 'react'
import { ValidateType } from 'recoil/validate/atom'
import styled from 'styled-components'
import arrow from 'assets/img/form/termsDetailRightArrow.svg'
import termsBtn from 'assets/img/form/terms-btn.png'
import termsBtnActive from 'assets/img/form/terms-btn-active.png'
import STYLE_GUIDE from 'service/constants/styleGuide'
import { useModal } from 'hooks'
import {SetterOrUpdater, useRecoilValue} from 'recoil'
import {refundLGTerms} from '../../../recoil/user/atom'
const RefundContainer = styled.div`
  width: 100%;
`
const RefundTermCheckboxContainer = styled.div``
const RefundTermCheckbox = styled.input`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: none;
`
const RefundTermTitle = styled.label<{ active: boolean }>`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: -0.05em;
    cursor: pointer;
    text-align: left;
    margin-top: 10px;
    padding-left: 30px;
    background: center left / contain no-repeat url(${(props) => (props.active ? termsBtnActive : termsBtn)});
    background-size: 4%;
    display: flex;
    align-items: center;
    height: 32px;
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    line-height: 20px;
    margin-top: 10px;
    letter-spacing: -0.05em;
    text-align: left;
    padding-left: 30px;
    background: center left / contain no-repeat url(${(props) => (props.active ? termsBtnActive : termsBtn)});
    display: flex;
    align-items: center;
    height: 20px;
  }
`
const RefundTermOpenBtn = styled.button`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: auto auto 2px 10px;
    border: none;
    background: none;
    cursor: pointer;
    > img {
      display: flex;
      margin-left: -5px;
      margin-top: -22px;
      width: 17px;
      height: 17px;
    }
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    margin: auto auto 2px 10px;
    border: none;
    background: none;
    cursor: pointer;
    > img {
      display: flex;
      margin-left: -5px;
      margin-top: 0px;
      width: 15px;
      height: 15px;
    }
  }
`
const RefundTermSubTitle = styled.p`
  @media screen and (min-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin: 5px 0;
    letter-spacing: -0.05em;
    text-align: left;
    padding-inline-start: 30px;
    color: ${STYLE_GUIDE.COLOR.grey03};
  }
  @media screen and (max-width: ${STYLE_GUIDE.SIZE.maxMobileSize}) {
    font-size: 16px;
    padding-left: 27px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: -0.05em;
    text-align: left;
    color: ${STYLE_GUIDE.COLOR.grey03};
  }
`
interface RefundTermPropType {
  title: ReactNode
  state: TermsType
  setState: SetterOrUpdater<TermsType>
  validate: ReturnValidate<ValidateType>
  subTitle?: ReactNode
}
const RefundTerm = ({ title, state, setState, validate, subTitle }: RefundTermPropType) => {
  const refundTerms = useRecoilValue(refundLGTerms)

  const modal = useModal()
  const onChange = () => {
    setState(state !== 1 ? 1 : 0)
    if (state === 0) {
      validate.recoilState[1]({ valid: false, msg: '' })
    }
  }
  const handleTermsDetail = () => {
    modal.alert({
      title: `${refundTerms.LgupTitle}`,
      contents:
        <div className='refund_modal_terms'>
          <div dangerouslySetInnerHTML={{ __html: refundTerms.LgupTerms }} />
        </div>,
      isXBtn: false
    })
  }

  return (
    <RefundContainer>
      <RefundTermCheckboxContainer>
        <RefundTermTitle active={state === 1}>
          <RefundTermCheckbox type='checkbox' onChange={onChange} />
          {title}
          <RefundTermOpenBtn onClick={handleTermsDetail}>
            <img src={arrow} alt='' />
          </RefundTermOpenBtn>
        </RefundTermTitle>
      </RefundTermCheckboxContainer>
      {subTitle && <RefundTermSubTitle>{subTitle}</RefundTermSubTitle>}
    </RefundContainer>
  )
}

export default RefundTerm
