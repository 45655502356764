import { SetterOrUpdater, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { otpCarNumber, otpTelecom } from 'recoil/otp/atom'
import { DisabledType, inputDisabled, ValidateType, validateTypeFam } from 'recoil/validate/atom'
import { formDisabled } from 'recoil/validate/withFormItem'

type ValidFuncPropsType = string | undefined

export interface ReturnValidate<T> {
  recoilState: [T, SetterOrUpdater<T>]
  validFunc: (value: ValidFuncPropsType) => boolean
  btnDisabled?: DisabledType
  inputDisabled?: DisabledType
}

const useCheckFunc = () => {
  const otpCarNum = useRecoilValue(otpCarNumber)
  const otpTel = useRecoilValue(otpTelecom)
  const pNumberValidState = useRecoilState(validateTypeFam('phoneNumber'))
  const certNumValidState = useRecoilState(validateTypeFam('certNumber'))
  const carNumValidState = useRecoilState(validateTypeFam('carNumber'))
  const certCarNumberValidState = useRecoilState(validateTypeFam('certCarNumber'))
  const joinTermsValidState = useRecoilState(validateTypeFam('terms'))
  const qnaTypeValidState = useRecoilState(validateTypeFam('qnaType'))
  const qnaUNameValidState = useRecoilState(validateTypeFam('qnaUName'))
  const qnaPNumberValidState = useRecoilState(validateTypeFam('qnaPNumber'))
  const qnaEMailValidState = useRecoilState(validateTypeFam('qnaEMail'))
  const qnaTitleValidState = useRecoilState(validateTypeFam('qnaTitle'))
  const qnaContentsValidateState = useRecoilState(validateTypeFam('qnaContents'))
  const qnaTermAgreeValidState = useRecoilState(validateTypeFam('qnaAgree'))
  const successUNameValidState = useRecoilState(validateTypeFam('successUName'))
  const successBenefitValidState = useRecoilState(validateTypeFam('successBenefit'))
  const successPostcodeValidState = useRecoilState(validateTypeFam('successPostcode'))
  const successAdd1 = useRecoilState(validateTypeFam('successAddress1'))

  const successRecvNumValidState = useRecoilState(validateTypeFam('successRecvNumber'))
  const setSendBtnDisabled = useSetRecoilState(inputDisabled('sendBtn'))

  const refundBankTypeValidState = useRecoilState(validateTypeFam('refundBankType'))
  const refundAgreeValidState = useRecoilState(validateTypeFam('refundAgree'))
  const refundPNumberValidState = useRecoilState(validateTypeFam('refundPNumber'))
  const refundUNameValidState = useRecoilState(validateTypeFam('refundUName'))
  const refundBankAccountValidState = useRecoilState(validateTypeFam('refundBankAccount'))
  const refundCaptchaAuthTokenState = useRecoilState(validateTypeFam('refundCaptchaAuthToken'))

  // function
  const pNumCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (!validRegExp.pNumber.test(value)) {
        pNumberValidState[1]({ valid: true, msg: '200' })
        return false
      } else {
        pNumberValidState[1]({ valid: false, msg: '' })
        setSendBtnDisabled({ isDisabled: false })
        return true
      }
    } else {
      pNumberValidState[1]({ valid: true, msg: '030021' })
      return false
    }
  }
  const certCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (!validRegExp.certNum.test(value)) {
        certNumValidState[1]({ valid: true, msg: '203' })
        return false
      } else {
        certNumValidState[1]({ valid: false, msg: '' })
        return true
      }
    } else {
      certNumValidState[1]({ valid: true, msg: '205' })
      return false
    }
  }
  const carNumCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (!validRegExp.carNum.test(value)) {
        carNumValidState[1]({ valid: true, msg: '206' })
        return false
      } else {
        carNumValidState[1]({ valid: true, msg: '' })
        // carNumValidState[1]({valid: false, msg: ""})
        return true
      }
    } else {
      carNumValidState[1]({ valid: true, msg: '207' })
      return false
    }
  }
  const certCarNum = (value: ValidFuncPropsType) => {
    if (value) {
      if (otpCarNum !== value) {
        certCarNumberValidState[1]({ valid: true, msg: '207' })
        return false
      } else {
        // certCarNumberValidState[1]({valid: true, msg: ""})
        return true
      }
    } else {
      certCarNumberValidState[1]({ valid: true, msg: '207' })
      return false
    }
  }
  const couponCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (validRegExp.couponNum.test(value) && value.length < 12) {
        // couponNumberValidState[1]({ valid: true, msg: "" })
        return false
      } else {
        if (value.length === 12) {
          // couponNumberValidState[1]({ valid: true, msg: "" })
        } else if (value.length > 0) {
          if (value.length < 12) {
            // couponNumberValidState[1]({ valid: true, msg: "209" })
          }
        }
        return true
      }
    } else {
      // couponNumberValidState[1]({ valid: true, msg: "" })

      return false
    }
  }
  const joinTermsCheck = (value: ValidFuncPropsType) => {
    if (value) {
      const {
        Terms1Agree,
        Terms2Agree,
        Terms4Agree,
        Terms5Agree,
      }: TermsAgrees = JSON.parse(value)
      switch (otpTel) {
        case 0:
          if (Terms1Agree === 0 || Terms2Agree === 0) {
            joinTermsValidState[1]({ valid: true, msg: '210' })
            return false
          } else {
            joinTermsValidState[1]({ valid: false, msg: '' });
          }
          break
        case 1:
          if (Terms1Agree === 0 || Terms2Agree === 0 || Terms4Agree === 0) {
            joinTermsValidState[1]({ valid: true, msg: '210' })
            return false
          } else {
            joinTermsValidState[1]({ valid: false, msg: '' });
          }
          break
        case 2:
          if (Terms1Agree === 0 || Terms2Agree === 0 || Terms4Agree === 0 || Terms5Agree === 0) {
            joinTermsValidState[1]({ valid: true, msg: '210' })
            return false
          } else {
            joinTermsValidState[1]({ valid: false, msg: '' });
          }
          break;
        default: joinTermsValidState[1]({ valid: false, msg: '' }); return false
      }
      return true
    } else {
      joinTermsValidState[1]({ valid: true, msg: '210' })
      return false
    }
  }
  const qnaTypeCheck = (value: ValidFuncPropsType) => {
    // console.log("typeCheck", value)
    if (value === '0') {
      qnaTypeValidState[1]({ valid: true, msg: '211' })
      return false
    } else {
      qnaTypeValidState[1]({ valid: false, msg: '' })
      return true
    }
  }
  const uNameCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length > 1) {
        qnaUNameValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        qnaUNameValidState[1]({ valid: true, msg: '212' })
        return false
      }
    } else {
      qnaUNameValidState[1]({ valid: true, msg: '212' })
      return false
    }
  }
  const qnaPNumCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (validRegExp.pNumber.test(value)) {
        if (value.length > 10) {
          qnaPNumberValidState[1]({ valid: false, msg: '' })
        } else {
          qnaPNumberValidState[1]({ valid: true, msg: '214' })
        }
        return true
      } else {
        qnaPNumberValidState[1]({ valid: true, msg: '214' })
        return false
      }
    } else {
      qnaPNumberValidState[1]({ valid: true, msg: '213' })
      return false
    }
  }
  const qnaEmailCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (validRegExp.email.test(value)) {
        qnaEMailValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        qnaEMailValidState[1]({ valid: true, msg: '216' })
        return false
      }
    } else {
      qnaEMailValidState[1]({ valid: true, msg: '215' })
      return false
    }
  }
  const qnaTitleCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length < 30) {
        qnaTitleValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        qnaTitleValidState[1]({ valid: true, msg: '' })
        return false
      }
    } else {
      qnaTitleValidState[1]({ valid: true, msg: '217' })
      return false
    }
  }
  const qnaContentsCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length <= 500) {
        qnaContentsValidateState[1]({ valid: false, msg: '' })
        return true
      } else {
        qnaContentsValidateState[1]({ valid: true, msg: '218' })
        return false
      }
    } else {
      qnaContentsValidateState[1]({ valid: true, msg: '218' })
      return false
    }
  }
  const qnaTermAgreeCheck = (value: ValidFuncPropsType) => {
    if (`${value}` !== '1') {
      qnaTermAgreeValidState[1]({ valid: true, msg: '219' })
      return false
    }
    qnaTermAgreeValidState[1]({ valid: false, msg: '' })
    return true
  }
  const successAgreeCheck = (value: ValidFuncPropsType) => {
    if (`${value}` !== '1') {
      successBenefitValidState[1]({ valid: true, msg: '210' })
      return false
    } else {
      successBenefitValidState[1]({ valid: false, msg: '' })
      return true
    }
  }
  const successUNameCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length > 1) {
        successUNameValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        successUNameValidState[1]({ valid: true, msg: '212' })
        return false
      }
    } else {
      successUNameValidState[1]({ valid: true, msg: '212' })
      return false
    }
  }
  const successPostcodeCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length > 0) {
        successPostcodeValidState[1]({ valid: false, msg: '' })
        successAdd1[1]({ valid: false, msg: '' })
        return true
      } else {
        successPostcodeValidState[1]({ valid: true, msg: 'j' })
        successAdd1[1]({ valid: true, msg: '2j' })
        return false
      }
    } else {
      successPostcodeValidState[1]({ valid: true, msg: '220' })
      successAdd1[1]({ valid: true, msg: '220' })
      return false
    }
  }
  const successRecvNumCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (validRegExp.pNumber.test(value) && value.length > 9 && value.length < 12) {
        successRecvNumValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        successRecvNumValidState[1]({ valid: true, msg: '214.5' })
        return false
      }
    } else {
      successRecvNumValidState[1]({ valid: true, msg: '213.5' })
      return false
    }
  }
  const refundBankTypeCheck = (value: ValidFuncPropsType) => {
    if (value === '0') {
      refundBankTypeValidState[1]({ valid: true, msg: '223' })
      return false
    } else {
      refundBankTypeValidState[1]({ valid: false, msg: '' })
      return true
    }
  }
  const refundTermAgreeCheck = (value: ValidFuncPropsType) => {
    if (`${value}` !== '1') {
      refundAgreeValidState[1]({ valid: true, msg: '225' })
      return false
    }
    refundAgreeValidState[1]({ valid: false, msg: '' })
    return true
  }
  const refundPNumCheck = (value: ValidFuncPropsType) => {
    if (value) {
      const formattedValue = value.replace(/-/g, '')
      if (validRegExp.pNumber.test(formattedValue)) {
        if (formattedValue.length >= 10) {
          refundPNumberValidState[1]({ valid: false, msg: '' })
          setSendBtnDisabled({ isDisabled: false })
          return true
        } else {
          refundPNumberValidState[1]({ valid: true, msg: '214' })
          setSendBtnDisabled({ isDisabled: true })
          return false
        }
      } else {
        refundPNumberValidState[1]({ valid: true, msg: '214' })
        setSendBtnDisabled({ isDisabled: true })
        return false
      }
    } else {
      refundPNumberValidState[1]({ valid: true, msg: '213' })
      setSendBtnDisabled({ isDisabled: true })
      return false
    }
  }
  const refundUNameCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (validRegExp.CompleteKor.test(value)) {
        if (value.length > 1) {
          refundUNameValidState[1]({ valid: false, msg: '' })
          return true
        } else {
          refundUNameValidState[1]({ valid: true, msg: '212.5' })
          return false
        }
      } else {
        refundUNameValidState[1]({ valid: true, msg: '212.5' })
        return false
      }
    } else {
      refundUNameValidState[1]({ valid: true, msg: '212.5' })
      return false
    }
  }
  const refundBankAccountCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (value.length > 7) {
        refundBankAccountValidState[1]({ valid: false, msg: '' })
        return true
      } else {
        refundBankAccountValidState[1]({ valid: true, msg: '224' })
        return false
      }
    } else {
      refundBankAccountValidState[1]({ valid: true, msg: '224' })
      return false
    }
  }
  const refundCaptchaAuthTokenCheck = (value: ValidFuncPropsType) => {
    if (value) {
      if (!validRegExp.certNum.test(value)) {
        refundCaptchaAuthTokenState[1]({ valid: true, msg: '203' })
        return false
      } else {
        refundCaptchaAuthTokenState[1]({ valid: false, msg: '' })
        return true
      }
    } else {
      refundCaptchaAuthTokenState[1]({ valid: true, msg: '205' })
      return false
    }
  }

  return {
    pNumCheck,
    certCheck,
    certCarNum,
    carNumCheck,
    couponCheck,
    joinTermsCheck,
    qnaTypeCheck,
    uNameCheck,
    qnaPNumCheck,
    qnaEmailCheck,
    qnaTitleCheck,
    qnaContentsCheck,
    qnaTermAgreeCheck,
    successUNameCheck,
    successPostcodeCheck,
    successRecvNumCheck,
    successAgreeCheck,
    refundBankTypeCheck,
    refundTermAgreeCheck,
    refundPNumCheck,
    refundUNameCheck,
    refundBankAccountCheck,
    refundCaptchaAuthTokenCheck
  }
}

interface ValidRegExpType {
  [index: string]: RegExp

  space: RegExp
  number: RegExp
  eng: RegExp
  kor: RegExp
  pNumber: RegExp
  certNum: RegExp
  carNum: RegExp
  couponNum: RegExp
  symbol: RegExp
}

export const validRegExp: ValidRegExpType = {
  space: /(\s*)/g,
  number: /^[0-9]/g,
  strNum: /^["0"-"9"]/g,
  eng: /[a-z|A-Z]/g,
  kor: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
  kor2: /[^ㄱ-힣]gi/,
  pNumber: /^01([0|1|6|7|8|9]?)?([0-9]{3,4})?([0-9]{4})$/,
  certNum: /^[0-9]{4}/,
  carNum: /^[0-9]{2,3}[가-힣]{1}[0-9]{4}$|^[가-힣]{2,3}[0-9]{1,2}[가-힣]{1}[0-9]{4}$|^[가-힣]{2}[0-9]{6}$/,
  couponNum: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/g,
  symbol: /[\\{\\}\\[\]\\/?.,;:|\\)*~`!^\-_+<>@\\#$%&\\\\=\\(\\'\\"]/g,
  email: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
  CompleteKor: /^[가-힣]+$/
}
const useReplacer = () => {
  const replaceStrSymbolSpace = (str: string): string => replaceStr(replaceSymbol(replaceSpace(str)))
  const replaceNumber = (str: string): string => str.replaceAll(validRegExp.number, '').replaceAll('0', '')
  const replaceSpace = (str: string): string => str.replaceAll(validRegExp.space, '')
  const replaceKor = (str: string): string => str.replaceAll(validRegExp.kor, '')
  const replaceEng = (str: string): string => str.replaceAll(validRegExp.eng, '')
  const replaceStr = (str: string): string => replaceKor(replaceEng(str))
  const replaceSymbol = (str: string): string => str.replaceAll(validRegExp.symbol, '')
  const replacePNumber = (str: string): string => replaceStrSymbolSpace(str).slice(0, 11)
  const replaceCertNumber = (str: string): string => replaceStrSymbolSpace(str).slice(0, 4)
  const replaceCoupon = (str: string): string => replaceKor(replaceSymbol(replaceSpace(str))).slice(0, 12)
  const replaceCar = (str: string): string => replaceEng(replaceSymbol(replaceSpace(str).slice(0, 10)))
  const replaceName = (str: string): string => replaceNumber(replaceSymbol(replaceSpace(str))).slice(0, 10)
  const replaceBankAccount = (str: string): string => replaceStrSymbolSpace(str).slice(0, 16)
  const replaceRefundName = (str: string): string => replaceNumber(replaceSymbol(replaceSpace(replaceEng(str))))

  return {
    replacePNumber,
    replaceCertNumber,
    replaceCoupon,
    replaceCar,
    replaceName,
    replaceBankAccount,
    replaceRefundName
  }
}
const useValidate = () => {
  const pNumberValidState = useRecoilState(validateTypeFam('phoneNumber'))
  const certNumValidState = useRecoilState(validateTypeFam('certNumber'))
  const carNumValidState = useRecoilState(validateTypeFam('carNumber'))
  const certCarNumberValidState = useRecoilState(validateTypeFam('certCarNumber'))
  const couponNumberValidState = useRecoilState(validateTypeFam('couponNumber'))
  const termsValidState = useRecoilState(validateTypeFam('terms'))
  // qna
  const qnaTypeValidState = useRecoilState(validateTypeFam('qnaType'))
  const qnaUNameValidState = useRecoilState(validateTypeFam('qnaUName'))
  const qnaPNumberValidState = useRecoilState(validateTypeFam('qnaPNumber'))
  const qnaEMailValidState = useRecoilState(validateTypeFam('qnaEMail'))
  const qnaTitleValidState = useRecoilState(validateTypeFam('qnaTitle'))
  const qnaContentsValidateState = useRecoilState(validateTypeFam('qnaContents'))
  const qnaAgreeValidateState = useRecoilState(validateTypeFam('qnaAgree'))
  // success
  const successBenefitValidateState = useRecoilState(validateTypeFam('successBenefit'))
  const successUNameValidateState = useRecoilState(validateTypeFam('successUName'))
  const successPostcodeValidateState = useRecoilState(validateTypeFam('successPostcode'))
  const successAdd1ValidateState = useRecoilState(validateTypeFam('successAddress1'))
  const successAdd2ValidateState = useRecoilState(validateTypeFam('successAddress2'))
  const successRecvNumValidateState = useRecoilState(validateTypeFam('successRecvNumber'))
  // mypage
  const fNumValidateState = useRecoilState(validateTypeFam('fNumber'))
  // refund
  const refundBankValidState = useRecoilState(validateTypeFam('refundBankType'))
  const refundAgreeValidateState = useRecoilState(validateTypeFam('refundAgree'))
  const refundPNumberValidState = useRecoilState(validateTypeFam('refundPNumber'))
  const refundUNameValidState = useRecoilState(validateTypeFam('refundUName'))
  const refundBankAccountValidState = useRecoilState(validateTypeFam('refundBankAccount'))
  const refundCaptchaAuthTokenValidState = useRecoilState(validateTypeFam('refundCaptchaAuthToken'))
  // disabled
  const { sendBtn, certBtn, phoneNumberInput, certNumberInput } = useRecoilValue(formDisabled)
  const {
    pNumCheck,
    certCarNum,
    carNumCheck,
    certCheck,
    couponCheck,
    joinTermsCheck,
    qnaTypeCheck,
    uNameCheck,
    qnaPNumCheck,
    qnaEmailCheck,
    qnaTitleCheck,
    qnaContentsCheck,
    qnaTermAgreeCheck,
    successPostcodeCheck,
    successRecvNumCheck,
    successUNameCheck,
    successAgreeCheck,
    refundBankTypeCheck,
    refundTermAgreeCheck,
    refundPNumCheck,
    refundUNameCheck,
    refundBankAccountCheck,
    refundCaptchaAuthTokenCheck,
  } = useCheckFunc()
  const { replaceCar, replaceCertNumber, replaceCoupon, replacePNumber, replaceName, replaceBankAccount, replaceRefundName } = useReplacer()
  const phoneNumber: ReturnValidate<ValidateType> = {
    recoilState: pNumberValidState,
    validFunc: pNumCheck,
    btnDisabled: sendBtn,
    inputDisabled: phoneNumberInput
  }
  const certNumber: ReturnValidate<ValidateType> = {
    recoilState: certNumValidState,
    validFunc: certCheck,
    btnDisabled: certBtn,
    inputDisabled: certNumberInput
  }
  const certCarNumber: ReturnValidate<ValidateType> = {
    recoilState: certCarNumberValidState,
    validFunc: certCarNum
  }
  const carNumber: ReturnValidate<ValidateType> = {
    recoilState: carNumValidState,
    validFunc: carNumCheck
  }
  const couponNumber: ReturnValidate<ValidateType> = {
    recoilState: couponNumberValidState,
    validFunc: couponCheck
  }
  const termsAgree: ReturnValidate<ValidateType> = {
    recoilState: termsValidState,
    validFunc: joinTermsCheck
  }
  const qnaUNameV: ReturnValidate<ValidateType> = {
    recoilState: qnaUNameValidState,
    validFunc: uNameCheck
  }
  const qnaPNumberV: ReturnValidate<ValidateType> = {
    recoilState: qnaPNumberValidState,
    validFunc: qnaPNumCheck
  }
  const qnaEMailV: ReturnValidate<ValidateType> = {
    recoilState: qnaEMailValidState,
    validFunc: qnaEmailCheck
  }
  const qnaTitleV: ReturnValidate<ValidateType> = {
    recoilState: qnaTitleValidState,
    validFunc: qnaTitleCheck
  }
  const qnaContentsV: ReturnValidate<ValidateType> = {
    recoilState: qnaContentsValidateState,
    validFunc: qnaContentsCheck
  }
  const qnaAgreeV: ReturnValidate<ValidateType> = {
    recoilState: qnaAgreeValidateState,
    validFunc: qnaTermAgreeCheck
  }
  const successBenefit: ReturnValidate<ValidateType> = {
    recoilState: successBenefitValidateState,
    validFunc: successAgreeCheck
  }
  const successUNAme: ReturnValidate<ValidateType> = {
    recoilState: successUNameValidateState,
    validFunc: successUNameCheck
  }
  const successPCode: ReturnValidate<ValidateType> = {
    recoilState: successPostcodeValidateState,
    validFunc: successPostcodeCheck,
    inputDisabled: { isDisabled: true }
  }
  const successAdd1: ReturnValidate<ValidateType> = {
    recoilState: successAdd1ValidateState,
    validFunc: successPostcodeCheck,
    inputDisabled: { isDisabled: true }
  }
  const successAdd2: ReturnValidate<ValidateType> = {
    recoilState: successAdd2ValidateState,
    validFunc: () => true
  }
  const successRecvNum: ReturnValidate<ValidateType> = {
    recoilState: successRecvNumValidateState,
    validFunc: successRecvNumCheck
  }
  const addFNumber: ReturnValidate<ValidateType> = {
    recoilState: fNumValidateState,
    validFunc: () => false
  }
  const refundBankTypeV: ReturnValidate<ValidateType> = {
    recoilState: refundBankValidState,
    validFunc: refundBankTypeCheck,
    inputDisabled: { isDisabled: false }
  }
  const qnaTypeV: ReturnValidate<ValidateType> = {
    recoilState: qnaTypeValidState,
    validFunc: qnaTypeCheck,
    inputDisabled: { isDisabled: false }
  }
  const refundAgreeV: ReturnValidate<ValidateType> = {
    recoilState: refundAgreeValidateState,
    validFunc: refundTermAgreeCheck
  }
  const refundPNumberV: ReturnValidate<ValidateType> = {
    recoilState: refundPNumberValidState,
    validFunc: refundPNumCheck,
    btnDisabled: sendBtn,
    inputDisabled: phoneNumberInput
  }
  const refundUNameV: ReturnValidate<ValidateType> = {
    recoilState: refundUNameValidState,
    validFunc: refundUNameCheck
  }
  const refundBankAccountV: ReturnValidate<ValidateType> = {
    recoilState: refundBankAccountValidState,
    validFunc: refundBankAccountCheck
  }
  const refundCaptchaAuthTokenV: ReturnValidate<ValidateType> = {
    recoilState: refundCaptchaAuthTokenValidState,
    validFunc: refundCaptchaAuthTokenCheck,
  }
  return {
    join: {
      phoneNumber,
      certNumber,
      carNumber,
      certCarNumber,
      couponNumber,
      termsAgree
    },
    qna: {
      qnaTypeV,
      qnaUNameV,
      qnaPNumberV,
      qnaEMailV,
      qnaTitleV,
      qnaContentsV,
      qnaAgreeV
    },
    success: {
      successBenefit,
      successUNAme,
      successPCode,
      successAdd1,
      successAdd2,
      successRecvNum
    },
    user: {
      addFNumber
    },
    replace: {
      replacePNumber,
      replaceCoupon,
      replaceCar,
      replaceCertNumber,
      replaceName,
      replaceBankAccount,
      replaceRefundName,
    },
    refund: {
      refundBankTypeV,
      refundAgreeV,
      refundPNumberV,
      refundUNameV,
      refundBankAccountV,
      refundCaptchaAuthTokenV,
    }
  }
}
export default useValidate
